import { FilterAlt } from "@mui/icons-material";
import { Popover } from "@mui/material";
import { cloneElement, useState } from "react";

export default function ColumnMenu({
  name,
  hovered,
  filters,
  children,
  onClose,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const hasCustomFilter = filters.hasCustomization(name);
  return (
    <>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={() => {
          setAnchorEl(null);
          onClose();
        }}
      >
        {cloneElement(children, { dismiss: () => setAnchorEl(null) })}
      </Popover>
      <FilterAlt
        fontSize="small"
        color={hasCustomFilter ? "primary" : "inherit"}
        style={{
          opacity: hovered || hasCustomFilter ? 0.5 : 0,
          transition: "opacity 200ms",
          margin: "-.3em",
          cursor: "pointer",
        }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className={"ui-filter-button-" + name}
      ></FilterAlt>
    </>
  );
}

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

export default function UserNameAndEmail({ name, email }) {
  const theme = useTheme();
  return (
    <Box display="flex">
      <Box flexShrink>
        {name}
        <Typography variant="body2" color={theme.palette.text.secondary}>
          {email}
        </Typography>
      </Box>
    </Box>
  );
}

import { useTranslation } from "react-i18next";
import ProjectState from "../model/ProjectState";
import { useConfirmationDialog } from "../providers/ConfirmationDialogProvider";
import { useProjectApi } from "../providers/ProjectApiProvider";
import { useSnackbar } from "../providers/SnackbarProvider";
import { resetGeneration, startCrownGeneration } from "../services/apiService";
import useQuotaExceededDialog from "./useQuotaExceededDialog";

export default function useRegenerateCurrentProjectPrompt() {
  const { project, saveProject } = useProjectApi();
  const { showConfirmationDialog } = useConfirmationDialog();
  const { showAlert } = useSnackbar();
  const { t } = useTranslation();
  const showQuotaExceededDialog = useQuotaExceededDialog();

  return async () => {
    if (
      await showConfirmationDialog({
        title: t("Regenerate project?"),
        message: (
          <>
            <p>
              {t("projects.actions.regenerateProjectConfirmation.paragraph1")}
            </p>
            <p>
              {t("projects.actions.regenerateProjectConfirmation.paragraph2")}
            </p>
          </>
        ),
      })
    ) {
      try {
        await resetGeneration(project.id);
        await saveProject({
          state: ProjectState.CROWN_GENERATION,
        });
        for (const tooth of project.teethUnderRepair) {
          await startCrownGeneration(project.id, tooth);
        }
      } catch (e) {
        if (e.response.status === 429) {
          showQuotaExceededDialog(project.ownerOrganization);
        } else {
          console.error(e);
          showAlert(t("Failed to regenerate project"));
        }
      }
    }
  };
}

import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ToothNumberSelect from "./ToothNumberSelect";
import ToothPicker from "./ToothPicker";

export default function MultiToothPicker(props) {
  const { t } = useTranslation();
  const { jaw, selectedTeeth, onChange, label, showClear } = props;

  const [hover, setHover] = useState(null);

  const toggle = (tooth) => {
    if (selectedTeeth.indexOf(tooth) != -1) {
      onChange(selectedTeeth.filter((x) => x != tooth));
    } else {
      onChange([...selectedTeeth, tooth]);
    }
  };

  const highlightedTeeth = [...selectedTeeth, hover].filter((x) => x !== null);

  return (
    <>
      <Box sx={{ marginTop: "10px" }} className="ui-tooth-picker">
        <Grid container alignItems="stretch" columnSpacing={2}>
          <Grid item>
            <ToothPicker
              size={350}
              highlightedTeeth={highlightedTeeth}
              onClick={toggle}
              onHover={setHover}
              jaw={jaw}
            />
          </Grid>
          <Grid
            item
            xs
            container
            direction="row"
            alignItems="flex-end"
            justifyItems="stretch"
          >
            <Grid item xs={12} sx={{ width: "100px" }}>
              <ToothNumberSelect
                multiple
                label={label}
                value={selectedTeeth}
                onChange={onChange}
                jaw={jaw}
              />
            </Grid>
            <Grid item marginLeft={"auto"}>
              {showClear ? (
                <Button
                  disabled={selectedTeeth.length === 0}
                  onClick={() => onChange([])}
                >
                  {t("Clear")}
                </Button>
              ) : (
                <>&nbsp;</>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

import { Login } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { registerNewUser } from "../services/apiService";
import ReCAPTCHA from "react-google-recaptcha";
import "./Root.css";
import config from "../config";

function WelcomeDialog({ open }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [captchaResponse, setCaptchaResponse] = useState(null);

  const handleContinue = () => {
    registerNewUser(captchaResponse).then(() => navigate("/home"));
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          padding: 15,
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        <div>
          <img
            src="/logo48.png"
            alt="logo"
            width="48"
            height="48"
            style={{ display: "inline-block" }}
          />
        </div>
        {t("Welcome")}
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center", overflow: "visible" }}>
        <Box sx={{ my: 3 }}>
          <ReCAPTCHA
            sitekey={config.reCaptchaSitekey}
            onChange={setCaptchaResponse}
          />
        </Box>

        <Button
          startIcon={<Login />}
          to="/home"
          variant="contained"
          sx={{ textTransform: "none", whiteSpace: "nowrap" }}
          onClick={handleContinue}
          disabled={!captchaResponse}
        >
          {t("Continue")}
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default function WelcomeNewUser() {
  const [open, setOpen] = useState(true);

  return (
    <header className="Root-header">
      <WelcomeDialog open={open} onClose={() => setOpen(false)} />
    </header>
  );
}

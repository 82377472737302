import { Box, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Loading() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 200);
    return () => clearTimeout(timeout);
  });

  return (
    <Box
      sx={{
        textAlign: "center",
        py: "25vh",
        mx: "auto",
        width: "35%",
        visibility: show ? "" : "hidden",
      }}
    >
      <Typography variant="h6">{t("Loading...")}</Typography>
      <LinearProgress sx={{ margin: "auto", width: "60%" }} />
    </Box>
  );
}

import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import socketIOClient from "socket.io-client";
import config from "../config";
import { useAuth } from "../providers/authProvider";
import { useSnackbar } from "./SnackbarProvider";

const SocketIoContext = createContext(null);

export const useSocketIo = () => useContext(SocketIoContext);

export const SocketIoConsumer = SocketIoContext.Consumer;

export function SocketIoProvider(props) {
  const { showAlert, showSuccessAlert } = useSnackbar();
  const [socket, setSocket] = useState(null);
  const auth = useAuth();
  const connectionWasLost = useRef(false);
  const { t } = useTranslation();

  useEffect(() => {
    auth
      .getUser()
      .then((user) => {
        const socket = socketIOClient(new URL(config.backendUrl).origin, {
          path: "/api/socket.io",
          extraHeaders: { Authorization: `Bearer ${user.access_token}` },
        });
        socket.on("connect_error", async () => {
          showAlert(t("Server connection lost"));
          connectionWasLost.current = true;

          // renew the token in the authorization header and reconnect
          const user = await auth.getUser();
          socket.io.opts.extraHeaders.Authorization =
            "Bearer " + user.access_token;
          socket.connect();
        });
        socket.on("connect", () => {
          if (connectionWasLost.current) {
            connectionWasLost.current = false;
            showSuccessAlert(t("Server connection restored"));
          }
        });
        setSocket(socket);
      })
      .catch(() => {});
  }, [auth]);

  return (
    <SocketIoContext.Provider value={socket}>
      {props.children}
    </SocketIoContext.Provider>
  );
}

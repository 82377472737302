import { Alert, AlertTitle, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function LoadingError({ error }) {
  const { t } = useTranslation();

  const message = (() => {
    switch (error.status) {
      case 403:
        return t("Access denied");
      case 404:
        return t("Not found");
      default:
        return null;
    }
  })();

  if (!message) {
    return (
        <Box sx={{ my: "2vh", mx: "auto", width: "50%" }}>
          <img src="/unexpected-error.png"
               alt="unexpected error"
               width="100%"
               style={{ display: "inline-block" }}/>
        </Box>
    );
  }

  return (
    <Box sx={{ my: "20vh", mx: "auto", width: "35%" }}>
      <Alert severity="error">
        <AlertTitle>{t("Failed to load")}</AlertTitle>
        {message}
      </Alert>
    </Box>
  );
}

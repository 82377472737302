import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useRegenerateCurrentProjectPrompt from "../../hooks/useRegenerateCurrentProjectPrompt";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import WorkerVersions from "../system/WorkerVersions";

export default function WorkerVersionDialog({ onClose }) {
  const { project, saveProject } = useProjectApi();
  const [modifiedVersions, setModifiedVersions] = useState({});
  const { t } = useTranslation();

  const handleRegenerate = useRegenerateCurrentProjectPrompt();

  const handleOk = () => {
    const patch = Object.fromEntries(
      Object.entries(modifiedVersions).map(([k, v]) => [
        "workerVersions." + k,
        v,
      ])
    );

    const containsActualChanges = !_.isEqual(project.workerVersions, {
      ...project.workerVersions,
      ...modifiedVersions,
    });

    saveProject(patch)
      .then(() => onClose())
      .then(() => containsActualChanges && handleRegenerate());
  };

  const handleVersionChange = (imageName, newVersion) => {
    setModifiedVersions({ ...modifiedVersions, [imageName]: newVersion });
  };

  return (
    <Dialog onClose={onClose} open={true}>
      <DialogTitle>{t("AI Engine Versions")}</DialogTitle>
      <DialogContent>
        <WorkerVersions
          currentVersions={
            { ...project.workerVersions, ...modifiedVersions } || {}
          }
          onVersionsChange={handleVersionChange}
        />
        <DialogActions>
          <Button onClick={onClose}>{t("Cancel")}</Button>
          <Button onClick={handleOk} variant="contained">
            {t("Ok")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

import { Button, Snackbar } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function CookiesConsent() {
  const { t } = useTranslation();
  const [show, setShow] = useState(
    localStorage.getItem("cookiesConsent") !== "yes"
  );
  const handleAgreement = () => {
    localStorage.setItem("cookiesConsent", "yes");
    setShow(false);
  };
  return (
    <Snackbar
      open={show}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      message={t("cookiesConsent")}
      action={
        <Button size="small" onClick={handleAgreement}>
          {t("I agree")}
        </Button>
      }
      sx={{ width: "100px", marginBottom: 2 }}
    />
  );
}

import { MoreVert } from "@mui/icons-material";
import { Alert, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import { useOrganizationApi } from "../../providers/OrganizationApiProvider";
import { useProfile } from "../../providers/UserProfileProvider";
import QuotasDialog from "./QuotasDialog";

export default function OrganizationMenu({ onRename }) {
  const navigate = useNavigate();
  const { organization, deleteOrganization } = useOrganizationApi();
  const { isAdmin } = useProfile();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const { showConfirmationDialog } = useConfirmationDialog();
  const [showQuotasDialog, setShowQuotasDialog] = useState(false);

  const handleDelete = async () => {
    if (
      await showConfirmationDialog({
        title: t("Delete organization?"),
        message: (
          <>
            {!!organization.memberCount && (
              <Alert severity="warning" sx={{ marginY: 1 }}>
                {t("organizations.aboutToDeleteOrganizationWithMembers", {
                  nbMembers: organization.memberCount,
                })}
              </Alert>
            )}
            {!!organization.projectCount && (
              <Alert severity="warning" sx={{ marginY: 1 }}>
                {t("organizations.aboutToDeleteOrganizationWithProjects", {
                  nbProjects: organization.projectCount,
                })}
              </Alert>
            )}
          </>
        ),
      })
    ) {
      await deleteOrganization();
      navigate("/organizations");
    }
  };

  const handleEditQuotas = () => {
    setShowQuotasDialog(true);
  };

  return (
    <>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className="ui-organization-menu"
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            onRename();
            setAnchorEl(null);
          }}
        >
          {t("Rename organization")}
        </MenuItem>
        {isAdmin && (
          <MenuItem
            onClick={() => {
              handleDelete();
              setAnchorEl(null);
            }}
          >
            {t("Delete organization")}
          </MenuItem>
        )}
        {isAdmin && <Divider />}
        {isAdmin && (
          <MenuItem
            onClick={() => {
              handleEditQuotas();
              setAnchorEl(null);
            }}
          >
            {t("Edit quotas")}
          </MenuItem>
        )}
      </Menu>
      {showQuotasDialog && (
        <QuotasDialog onClose={() => setShowQuotasDialog(false)} />
      )}
    </>
  );
}

OrganizationMenu.propTypes = {
  onRename: PropTypes.func.isRequired,
};

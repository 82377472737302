import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import FeedbackLabel from "./FeedbackLabel";

export default function FeedbackFilter({ filter, setFilter }) {
  const setEnabled = (state, enabled) => {
    const newFilter = new Set(filter);
    if (enabled) newFilter.add(state);
    else newFilter.delete(state);
    setFilter(newFilter);
  };

  return (
    <FormGroup sx={{ p: 2 }}>
      {[-2, -1, 1, 2, 0].map((state, index) => (
        <FormControlLabel
          key={index}
          control={<Switch checked={filter.has(state)} />}
          label={<FeedbackLabel value={state} />}
          onChange={(event) => setEnabled(state, event.target.checked)}
        />
      ))}
    </FormGroup>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    showTutorial: true,
    currentTutorial: null,
    tutorials: {}, // permanent/persisted (see below)
    currentOrganization: null,
  },
  reducers: {
    setShowTutorial: (state, action) => {
      state.showTutorial = action.payload;
    },
    setcurrentTutorial: (state, action) => {
      state.currentTutorial = action.payload;
    },
    restartTutorial: (state, action) => {
      if (state.currentTutorial) {
        state.tutorials[state.currentTutorial] =
          state.tutorials[state.currentTutorial] || {};
        state.tutorials[state.currentTutorial].completed = false;
      }
      state.showTutorial = true;
    },
    setCompleteTutorial: (state, action) => {
      const { section, completed } = action.payload;
      state.tutorials[section] = state.tutorials[section] || {};
      state.tutorials[section].completed = completed;
    },
    setCurrentOrganization: (state, action) => {
      state.currentOrganization = action.payload;
    },
  },
});

export const actions = appSlice.actions;

const useAppActions = () => {
  const dispatch = useDispatch();
  return {
    setShowTutorial: useCallback((newVal) =>
      dispatch(appSlice.actions.setShowTutorial(newVal))
    ),
    setcurrentTutorial: useCallback((newVal) =>
      dispatch(appSlice.actions.setcurrentTutorial(newVal))
    ),
    restartTutorial: useCallback(() =>
      dispatch(appSlice.actions.restartTutorial())
    ),
    setCompleteTutorial: useCallback((section, completed) =>
      dispatch(appSlice.actions.setCompleteTutorial({ section, completed }))
    ),
    setCurrentOrganization: useCallback((org) =>
      dispatch(appSlice.actions.setCurrentOrganization(org))
    ),
  };
};

export const useAppState = () => ({
  ...useSelector((state) => state.app),
  ...useAppActions(),
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["tutorials"],
};

const persistedReducer = persistReducer(persistConfig, appSlice.reducer);

export default persistedReducer;

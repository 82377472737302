import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import { useAppState } from "../../reducers/app";

function get(item) {
  return typeof item === "function" ? item() : item;
}

export const Breadcrumbs = ({ levels }) => {
  const { t } = useTranslation();

  const title = t(get(levels[levels.length - 1]).name);
  document.title = title + " - Intellident";
  return (
    <MuiBreadcrumbs
      separator=">"
      aria-label="breadcrumb"
      sx={{ m: 2, fontSize: "smaller" }}
    >
      {levels.slice(0, -1).map((level, index) => (
        <Link
          key={index}
          underline="hover"
          color="inherit"
          to={get(level).path}
        >
          {t(get(level).name)}
        </Link>
      ))}
      <span>{title}</span>
    </MuiBreadcrumbs>
  );
};

function currentProject() {
  const { project } = useProjectApi();
  return {
    name: project.refNumber || "New Project",
    path: `/projects/${project.id}`,
  };
}

function currentOrganization() {
  const org = useAppState().currentOrganization ?? {
    id: "",
    name: "(...)",
  };
  return {
    name: org.name,
    path: `/organizations/${org.id}`,
  };
}

function projects() {
  const { organizationId } = useParams();
  return {
    name: "Projects",
    path:
      (organizationId ? `/organizations/${organizationId}` : "") + "/projects",
  };
}

export const HOME = { name: "Home", path: "/home" };
export const PROJECTS = projects;
export const PROJECT = currentProject;
export const SETTINGS = { name: "Settings" };
export const SYSTEM_SETTINGS = { name: "System Settings" };
export const NOTIFICATIONS_SETTINGS = { name: "Notifications" };
export const SURVEY = { name: "Feedback Survey" };
export const TOOTH_LABELLING = { name: "Tooth Labeling" };
export const MARGIN_LINES = { name: "Margin Lines" };
export const PROJECT_HISTORY = { name: "History" };
export const ORGANIZATIONS = { name: "Organizations", path: "/organizations" };
export const ORGANIZATION = currentOrganization;
export const ADMINISTRATORS = {
  name: "Administrators",
  path: "/administrators",
};
export const USERS = {
  name: "Users",
  path: "/users",
};
export const OPENSOURCE = { name: "Open Source Attribution" };

import { useTranslation } from "react-i18next";
import AdministratorsMembers from "../Components/system/AdministratorsMembers";
import { ADMINISTRATORS, HOME } from "../Components/system/Breadcrumbs";
import AdministratorsTutorial from "../Components/tutorial/AdministratorsTutorial";
import MainLayout from "../layout/MainLayout";

function Administrators() {
  const { t } = useTranslation();
  return (
    <MainLayout
      title={t("Administrators")}
      breadcrumbs={[HOME, ADMINISTRATORS]}
    >
      <AdministratorsTutorial />
      <AdministratorsMembers></AdministratorsMembers>
    </MainLayout>
  );
}

export default Administrators;

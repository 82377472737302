import { Done, Error, HourglassEmpty } from "@mui/icons-material";
import VisibilityOn from "@mui/icons-material/Visibility";
import { CircularProgress } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import Tutorial from "../widgets/Tutorial";

export default function CrownGenerationTutorial({ setContextExpanded }) {
  const { t } = useTranslation(["tutorial"]);

  const steps = [
    {
      target: "body",
      title: t("crownGeneration.general.title"),
      content: (
        <>
          <p>{t("crownGeneration.general.paragraph1")}</p>
        </>
      ),
      disableBeacon: true,
      placement: "center",
    },
    {
      target: ".ui-crownGeneration-context",
      content: (
        <>
          <p>{t("crownGeneration.inputs.paragraph1")}</p>
          <p>
            <Trans
              ns={["tutorial"]}
              i18nKey="crownGeneration.inputs.paragraph2"
            >
              text0
              <VisibilityOn style={{ verticalAlign: "middle" }}></VisibilityOn>
              text2.
            </Trans>
          </p>
        </>
      ),
      before: setContextExpanded,
      placement: "right",
    },
    {
      target: ".ui-crownGeneration-crowns",
      content: (
        <>
          <p>{t("crownGeneration.crowns.paragraph1")}</p>
          <p>
            <Trans
              ns={["tutorial"]}
              i18nKey="crownGeneration.crowns.paragraph2"
            >
              text0
              <Done style={{ verticalAlign: "middle" }}></Done>
              text2
              <Error style={{ verticalAlign: "middle" }}></Error>
              text4
              <CircularProgress
                size={24}
                color="inherit"
                style={{ verticalAlign: "middle" }}
              ></CircularProgress>
              text6
              <HourglassEmpty
                style={{ verticalAlign: "middle" }}
              ></HourglassEmpty>
              text8
            </Trans>
          </p>
          <p>
            <Trans
              ns={["tutorial"]}
              i18nKey="crownGeneration.crowns.paragraph3"
            >
              text0
              <VisibilityOn style={{ verticalAlign: "middle" }}></VisibilityOn>
              text2.
            </Trans>
          </p>
        </>
      ),
      before: setContextExpanded,
      placement: "right",
    },
    {
      target: ".ui-crowns-preview",
      content: (
        <>
          <p>{t("crownGeneration.preview.paragraph1")}</p>
          <p>{t("common.meshPreview.controls")}</p>
        </>
      ),
      placement: "left",
    },
    {
      target: ".ui-preview-toggle-fullscreen",
      content: (
        <>
          <p>{t("common.meshPreview.fullscreen")}</p>
        </>
      ),
      placement: "bottom",
    },
    {
      target: ".ui-project-menu",
      content: (
        <>
          <p>
            <Trans ns={["tutorial"]} i18nKey={"common.projectMenu"}>
              text0<em>text1</em>text2<em>text3</em>text4
            </Trans>
          </p>
        </>
      ),
      placement: "left",
    },
    {
      target: ".ui-file-download",
      content: (
        <>
          <p>{t("crownGeneration.download.paragraph1")}</p>
        </>
      ),
      placement: "right",
    },
    {
      target: ".ui-workflow-next-button",
      content: (
        <>
          <p>{t("crownGeneration.complete.paragraph1")}</p>
          <p>{t("crownGeneration.complete.paragraph2")}</p>
        </>
      ),
      placement: "left",
    },
  ];

  return <Tutorial steps={steps} section="crownGeneration" />;
}

import { createApi } from "@reduxjs/toolkit/query/react";
import config from "../config";
import axiosBaseQuery from "../utils/axiosBaseQuery";

export const administratorsApi = createApi({
  reducerPath: "administratorsApi",
  baseQuery: axiosBaseQuery({ baseUrl: config.backendUrl }),
  tagTypes: ["administrator"],
  endpoints: (builder) => ({
    getAdministrators: builder.query({
      query: () => ({ url: `/administrators` }),
      providesTags: ["administrator"],
    }),
    createAdministrator: builder.mutation({
      query: (body) => ({
        url: `/administrators/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["administrator"],
    }),
    deleteAdministrator: builder.mutation({
      query: (id) => ({ url: `/administrators/${id}`, method: "DELETE" }),
      invalidatesTags: ["administrator"],
    }),
    updateAdministrator: builder.mutation({
      query: ({ id, patch }) => ({
        url: `/administrators/${id}`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["administrator"],
    }),
  }),
});

export const {
  useCreateAdministratorMutation,
  useDeleteAdministratorMutation,
  useGetAdministratorsQuery,
  useUpdateAdministratorMutation,
} = administratorsApi;

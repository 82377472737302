import { createContext, useContext } from "react";
import authService from "../services/authService";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthConsumer = AuthContext.Consumer;

export function AuthProvider(props) {
  return (
    <AuthContext.Provider value={authService}>
      {props.children}
    </AuthContext.Provider>
  );
}

import { CssBaseline } from "@mui/material";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { I18nextProvider, useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { AuthProvider } from "./providers/authProvider";
import BackdropProvider from "./providers/BackdropProvider";
import ConfirmationDialogProvider from "./providers/ConfirmationDialogProvider";
import SnackbarProvider from "./providers/SnackbarProvider";
import { SocketIoProvider } from "./providers/SocketIoProvider";
import Router from "./routes/router";
import store from "./store";

i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    react: {
      useSuspense: false,
    },
  });

const MyThemeProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const lang = i18n?.language?.substring(0, 2) === "fr" ? "frFR" : "enUS";
  const themeHook = useTheme();
  const theme = createTheme(themeHook, locales[lang]);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <SnackbarProvider>
          <BackdropProvider>
            <ConfirmationDialogProvider>
              <AuthProvider>
                <SocketIoProvider>
                  <MyThemeProvider>
                    <CssBaseline />
                    <Router />
                  </MyThemeProvider>
                </SocketIoProvider>
              </AuthProvider>
            </ConfirmationDialogProvider>
          </BackdropProvider>
        </SnackbarProvider>
      </I18nextProvider>
    </Provider>
  );
}

export default App;

import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useProjectApi } from "../../providers/ProjectApiProvider";

export default function BackToProjectButton() {
  const { t } = useTranslation();
  const { project } = useProjectApi();
  return (
    <Button component={Link} to={".."}>
      {t("Back to project")}
    </Button>
  );
}

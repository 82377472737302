import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

import fr from "date-fns/locale/fr-CA";
registerLocale("fr", fr);

const fromNow = (f) => {
  const d = new Date();
  f(d);
  return d;
};

export default function DateFilter({ filter, setFilter }) {
  const { t, i18n } = useTranslation();

  const [interval, setInterval] = useState(
    filter.begin || filter.end ? "custom" : "any"
  );

  const setPredefinedFilter = (value) => {
    switch (value) {
      case "any":
        setFilter({});
        break;
      case "lasthour":
        setFilter({
          begin: fromNow((d) => d.setHours(d.getHours() - 1)),
          end: new Date(),
        });
        break;
      case "lastday":
        setFilter({
          begin: fromNow((d) => d.setDate(d.getDate() - 1)),
          end: new Date(),
        });
        break;
      case "lastweek":
        setFilter({
          begin: fromNow((d) => d.setDate(d.getDate() - 7)),
          end: new Date(),
        });
        break;
      case "lastmonth":
        setFilter({
          begin: fromNow((d) => d.setMonth(d.getMonth() - 1)),
          end: new Date(),
        });
        break;
    }
    setInterval(value);
  };

  const setCustomFilterWith = (newVal) => {
    setInterval("custom");
    setFilter({ ...filter, ...newVal });
  };

  const Picker = (props) => (
    <DatePicker
      {...props}
      dateFormat={
        i18n.language === "fr" ? "yyyy-MM-dd HH:mm" : "MM/dd/yyyy hh:mm aa"
      }
      showTimeSelect
      locale={i18n.language.substring(0, 2) === "fr" ? "fr" : "en"}
      timeCaption={t("Time")}
      timeIntervals={15}
      isClearable
    ></DatePicker>
  );

  return (
    <FormControl sx={{ p: 2 }}>
      <FormLabel>{t("Interval")}</FormLabel>
      <RadioGroup
        defaultValue={filter.begin || filter.end ? "custom" : "any"}
        onChange={(e) => setPredefinedFilter(event.target.value)}
        value={interval}
      >
        <FormControlLabel value="any" control={<Radio />} label={t("Any")} />
        <FormControlLabel
          value="lasthour"
          control={<Radio />}
          label={t("Last hour")}
        />
        <FormControlLabel
          value="lastday"
          control={<Radio />}
          label={t("Last day")}
        />
        <FormControlLabel
          value="lastweek"
          control={<Radio />}
          label={t("Last week")}
        />
        <FormControlLabel
          value="lastmonth"
          control={<Radio />}
          label={t("Last month")}
        />
        <FormControlLabel
          value="custom"
          control={<Radio />}
          label={t("Custom")}
        />
        <div style={{ display: "flex", marginLeft: "36px" }}>
          <Picker
            selected={filter.begin}
            onChange={(date) => setCustomFilterWith({ begin: date })}
            maxDate={filter.end}
          ></Picker>
          &nbsp;-&nbsp;
          <Picker
            selected={filter.end}
            onChange={(date) => setCustomFilterWith({ end: date })}
            minDate={filter.begin}
          ></Picker>
        </div>
      </RadioGroup>
    </FormControl>
  );
}

export default class ProjectState {
  static NEW = new ProjectState(0, "new");
  static SCANS = new ProjectState(1, "scans");
  static CROWN_GENERATION = new ProjectState(3, "crown_generation");
  static COMPLETE = new ProjectState(4, "complete");
  static ERROR = new ProjectState(5, "error");
  static ARCHIVED = new ProjectState(6, "archived");

  constructor(value, name) {
    this.value = value;
    this.name = name;
  }

  toJSON() {
    return this.name;
  }

  static valueOf(name) {
    const found = Object.values(ProjectState).find((s) => s.name === name);
    if (!found) throw new Error(`Invalid ProjectState value ${name}`);
    return found;
  }
}

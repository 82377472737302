import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProjectState from "../../model/ProjectState";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import ProjectInfoTutorial from "../tutorial/ProjectIntoTutorial";
import DataUsageConsent from "../widgets/DataUsageConsent";
import MultiToothPicker from "../widgets/MultiToothPicker";

export default function ProjectInfo({ setErrorOnPage }) {
  const [refNumberIsInvalid, setRefNumberIsInvalid] = useState(false);
  const { t } = useTranslation();

  const {
    project: { refNumber, state, teethUnderRepair, additionalInfo },
    saveProject,
  } = useProjectApi();

  function setTeethUnderRepair(teeth) {
    saveProject({
      teethUnderRepair: teeth,
      state: ProjectState.NEW,
    });
  }

  const onRefNumberChange = useMemo(
    () =>
      debounce((value) => {
        setRefNumberIsInvalid(!value);
        setErrorOnPage(!value);
        if (value) {
          saveProject({
            refNumber: value,
            state: state || ProjectState.NEW,
          });
        }
      }, 300),
    []
  );

  const onAdditionalInfoChange = useMemo(
    () =>
      debounce((value) => {
        saveProject({
          additionalInfo: value,
          state: state || ProjectState.NEW,
        });
      }, 300),
    []
  );

  return (
    <>
      <ProjectInfoTutorial />
      <Grid container spacing={3}>
        <Grid container item xs={6} direction="column">
          <Typography variant="h6" gutterBottom>
            {t("Case Information")}
          </Typography>
          <Box mt={3}>
            <TextField
              required
              id="refNumber"
              name="refNumber"
              label={t("Reference Number (e.g. Case ID)")}
              fullWidth
              variant="standard"
              defaultValue={refNumber || ""}
              onFocus={(event) => {
                event.target.select();
              }}
              error={refNumberIsInvalid}
              onChange={(e) => onRefNumberChange(e.target.value)}
              className="ui-refnumber"
            />
          </Box>
          <Box mt={3}>
            <TextField
              className="ui-additionnal-info"
              id="additionalInfo"
              name="additionalInfo"
              label={t("Additional information")}
              fullWidth
              autoComplete=""
              variant="standard"
              multiline
              defaultValue={additionalInfo || ""}
              onChange={(e) => onAdditionalInfoChange(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid container item xs={6} direction="column">
          <Typography variant="h6" gutterBottom>
            {t("Prepped Tooth Information")}
          </Typography>
          <Box mt={3}>
            <MultiToothPicker
              selectedTeeth={teethUnderRepair}
              onChange={setTeethUnderRepair}
              label={t("Prepped teeth")}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DataUsageConsent />
        </Grid>
      </Grid>
    </>
  );
}

import { createApi } from "@reduxjs/toolkit/query/react";
import config from "../config";
import axiosBaseQuery from "../utils/axiosBaseQuery";

export const organizationsApi = createApi({
  reducerPath: "organizationsApi",
  baseQuery: axiosBaseQuery({ baseUrl: config.backendUrl }),
  tagTypes: ["organization"],
  endpoints: (builder) => ({
    getOrganizations: builder.query({
      query: () => ({ url: `/organizations` }),
      providesTags: ["organization"],
    }),
    getOrganization: builder.query({
      query: (id) => ({ url: `/organizations/${id}` }),
      providesTags: (result, error, arg) => [{ type: "organization", id: arg }],
    }),
    createOrganization: builder.mutation({
      query: (body) => ({ url: `/organizations/`, method: "POST", body: body }),
      invalidatesTags: ["organization"],
    }),
    updateOrganization: builder.mutation({
      query: ({ id, patch }) => ({
        url: `/organizations/${id}`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["organization"],
    }),
    deleteOrganization: builder.mutation({
      query: (id) => ({ url: `/organizations/${id}`, method: "DELETE" }),
      invalidatesTags: ["organization"],
    }),
    addOrganizationMember: builder.mutation({
      query: ({ id, memberBody }) => ({
        url: `/organizations/${id}/members`,
        method: "POST",
        body: memberBody,
      }),
      invalidatesTags: ["organization"],
    }),
    updateOrganizationMember: builder.mutation({
      query: ({ id, memberId, patch }) => ({
        url: `/organizations/${id}/members/${memberId}`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["organization"],
    }),
    deleteOrganizationMember: builder.mutation({
      query: ({ id, memberId }) => ({
        url: `/organizations/${id}/members/${memberId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["organization"],
    }),
    respondInvitation: builder.mutation({
      query: ({ organizationId, memberId, accept }) => ({
        url: `/organizations/${organizationId}/members/${memberId}/actions/respondInvitation`,
        body: { accept },
        method: "POST",
      }),
    }),
    getQuotasUtilization: builder.query({
      query: (organizationId) => ({
        url: `/organizations/${organizationId}/quotasUtilization`,
      }),
    }),
    getResourceUtilization: builder.query({
      query: (organizationId) => ({
        url: `/organizations/${organizationId}/resourcesUtilization`,
      }),
    }),
  }),
});

export const {
  useCreateOrganizationMutation,
  useGetOrganizationQuery,
  useLazyGetOrganizationQuery,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationsQuery,
  useAddOrganizationMemberMutation,
  useUpdateOrganizationMemberMutation,
  useDeleteOrganizationMemberMutation,
  useRespondInvitationMutation,
  useGetResourceUtilizationQuery,
  useGetQuotasUtilizationQuery,
} = organizationsApi;

import { Grid, MenuItem, Select } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { HOME, SETTINGS } from "../Components/system/Breadcrumbs";
import MainLayout from "../layout/MainLayout";
import { useUpdateSettings } from "../providers/UserProfileProvider";
import { useGetUserProfileQuery } from "../services/userprofile";

function UserSettingsComponent() {
  const { data } = useGetUserProfileQuery();
  const updateSettings = useUpdateSettings();
  const { t, i18n } = useTranslation();

  const settings = data ? data.settings : {};

  const onLanguageChanged = (newLang) => {
    updateSettings({ lang: newLang });
  };

  return (
    <Grid container spacing={2} sx={{ mt: 5 }}>
      <Grid item container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="standard">{t("settings.language")}</Typography>
        </Grid>
        <Grid item>
          <Select
            sx={{ minWidth: 200 }}
            value={settings?.lang || i18n.language.substring(0, 2)}
            onChange={(e) => onLanguageChanged(e.target.value)}
          >
            <MenuItem value={"en"}>{t("settings.lang.english")}</MenuItem>
            <MenuItem value={"fr"}>{t("settings.lang.french")}</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid item container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="standard">{t("settings.notation")}</Typography>
        </Grid>
        <Grid item>
          <Select
            sx={{ minWidth: 200 }}
            value={settings ? settings.dentalNotation || "FDI" : ""}
            onChange={(e) => updateSettings({ dentalNotation: e.target.value })}
          >
            <MenuItem value={"FDI"}>FDI (ISO 3950)</MenuItem>
            <MenuItem value={"UNS"}>UNS</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
}

function UserSettings() {
  const { t } = useTranslation();

  return (
    <MainLayout title={t("User Settings")} breadcrumbs={[HOME, SETTINGS]}>
      <UserSettingsComponent />
    </MainLayout>
  );
}

export default UserSettings;

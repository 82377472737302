import { createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  useAddOrganizationMemberMutation,
  useDeleteOrganizationMemberMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationQuery,
  useUpdateOrganizationMemberMutation,
  useUpdateOrganizationMutation,
} from "../services/organizations";
import { useSnackbar } from "./SnackbarProvider";
import LoadingError from "../Components/system/LoadingError";
import { useAppState } from "../reducers/app";
import Loading from "../Components/Loading";

const organizationApiContext = createContext(null);

export const useOrganizationApi = () => useContext(organizationApiContext);

export const OrganizationApiConsumer = organizationApiContext.Consumer;

const OrganizationApiProvider = (props) => {
  const { organizationId } = useParams();
  const { showAlert } = useSnackbar();
  const { t } = useTranslation();

  const { data, error, isLoading } = useGetOrganizationQuery(organizationId);
  const [updateOrganization_] = useUpdateOrganizationMutation();
  const [deleteOrganization_] = useDeleteOrganizationMutation();
  const [addOrganizationMember_] = useAddOrganizationMemberMutation();
  const [updateOrganizationMember_] = useUpdateOrganizationMemberMutation();
  const [deleteOrganizationMember_] = useDeleteOrganizationMemberMutation();
  const { setCurrentOrganization } = useAppState();

  useEffect(() => {
    setCurrentOrganization(data);
    return () => setCurrentOrganization(null);
  }, [isLoading, data]);

  if (isLoading) return <Loading />;
  if (error) return <LoadingError error={error} />;

  const updateOrganization = (patch) =>
    updateOrganization_({ id: organizationId, patch })
      .unwrap()
      .catch((e) => {
        showAlert(t("Failed to save"));
        throw e;
      });

  const deleteOrganization = () =>
    deleteOrganization_(organizationId)
      .unwrap()
      .catch((e) => {
        showAlert(t("Failed to delete"));
        throw e;
      });

  const addOrganizationMember = (memberBody) =>
    addOrganizationMember_({ id: organizationId, memberBody })
      .unwrap()
      .catch((e) => {
        showAlert(t("Failed to add"));
        throw e;
      });

  const deleteOrganizationMember = (memberId) =>
    deleteOrganizationMember_({ id: organizationId, memberId })
      .unwrap()
      .catch((e) => {
        showAlert(t("Failed to delete"));
        throw e;
      });

  const updateOrganizationMember = (memberId, patch) =>
    updateOrganizationMember_({ id: organizationId, memberId, patch })
      .unwrap()
      .catch((e) => {
        showAlert(t("Failed to save"));
        throw e;
      });

  const project = {
    organization: data,
    updateOrganization,
    deleteOrganization,
    addOrganizationMember,
    updateOrganizationMember,
    deleteOrganizationMember,
  };

  return (
    <organizationApiContext.Provider value={project}>
      {props.children}
    </organizationApiContext.Provider>
  );
};

export function withOrganizationApiProvider(Component) {
  return (props) => (
    <>
      <OrganizationApiProvider>
        <Component {...props} />
      </OrganizationApiProvider>
    </>
  );
}

export default OrganizationApiProvider;

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useAuth } from "../../providers/authProvider";
import {
  browserDownload,
  downloadFile,
  mediastoreUrl,
  zipAndDownload,
} from "../../services/FileService";

const useStyles = makeStyles({
  buttonNoUppercase: {
    textTransform: "none",
    fontSize: "1rem",
  },
});

export default function FileDownload({
  projectId,
  file,
  files,
  downloadFilename,
  enabled,
  children,
  sx,
}) {
  const classes = useStyles();
  const auth = useAuth();
  const [processing, setProcessing] = useState(false);

  if ((file && files) || (!file && !files)) {
    throw new Error("Either 'file' or 'files' must be set; and not both");
  }

  async function download() {
    if (processing) return;

    const token = (await auth.getUser()).access_token;
    if (file) {
      browserDownload(
        mediastoreUrl(projectId, `${file}/download`),
        downloadFilename,
        token
      );
    } else if (files) {
      setProcessing(true);
      zipAndDownload(
        files.map(({ id, name }) => ({
          name,
          data: downloadFile(projectId, id, token),
        })),
        downloadFilename
      );
      setProcessing(false);
    }
  }

  return (
    <Button
      disabled={!enabled}
      startIcon={
        processing ? <CircularProgress size={20} /> : <FileDownloadIcon />
      }
      onClick={download}
      sx={{ flexGrow: 1, justifyContent: "left", ...sx }}
      className={`ui-file-download ${classes.buttonNoUppercase}`}
    >
      {children || downloadFilename}
    </Button>
  );
}

FileDownload.defaultProps = {
  enabled: true,
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getServerVersion } from "../../services/apiService";
import versionFile from "../../version.txt";

export default function AboutDialog({ open, onClose }) {
  const [frontEndVersion, setFrontEndVersion] = useState("");
  const [backEndVersion, setBackendEndVersion] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    fetch(versionFile)
      .then((r) => {
        return r.ok ? r.text() : "error";
      })
      .then(setFrontEndVersion);
    getServerVersion().then(setBackendEndVersion);
  }, []);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{t("About Intellident")}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            {t("App version:")}
          </Grid>
          <Grid item xs={6}>
            {frontEndVersion}
          </Grid>
          <Grid item xs={6}>
            {t("Server version:")}
          </Grid>
          <Grid item xs={6}>
            {backEndVersion}
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={onClose} variant="contained">
            {t("Ok")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

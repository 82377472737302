import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Gravatar from "react-gravatar";
import { useTranslation } from "react-i18next";
import { HOME, NOTIFICATIONS_SETTINGS } from "../Components/system/Breadcrumbs";
import UserNameAndEmail from "../Components/system/UserAndEmail";
import NotificationsTutorial from "../Components/tutorial/NotificationsTutorial";
import MainLayout from "../layout/MainLayout";
import { useSnackbar } from "../providers/SnackbarProvider";
import {
  useGetAdministratorsQuery,
  useUpdateAdministratorMutation,
} from "../services/administrators";
import LoadingError from "../Components/system/LoadingError";
import Loading from "../Components/Loading";

function AdminNotificationSettings() {
  const { data, error, isLoading } = useGetAdministratorsQuery();
  const [updateAdministrator] = useUpdateAdministratorMutation();
  const { showAlert } = useSnackbar();
  const { t } = useTranslation();

  if (isLoading) return <Loading />;
  else if (error) return <LoadingError error={error} />;

  const AdminSettingCheckbox = ({ admin, setting }) => {
    const settings = new Set(
      admin.notifications ? admin.notifications.split(",") : []
    );
    return (
      <Checkbox
        checked={settings.has(setting)}
        onChange={(event) => {
          if (event.target.checked) settings.add(setting);
          else settings.delete(setting);
          updateAdministrator({
            id: admin.id,
            patch: {
              notifications: Array.from(settings).join(","),
            },
          })
            .unwrap()
            .catch((e) => {
              showAlert(t("Failed to save"));
              throw e;
            });
        }}
      />
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} style={{ width: "0%" }}></TableCell>
            <TableCell rowSpan={2} padding="none" className="ui-admin-column">
              {t("Administrator")}
            </TableCell>
            <TableCell colSpan={3} align="center" className="ui-events-column">
              {t("Events")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" className="ui-new-user-registered">
              {t("New user registered")}
            </TableCell>
            <TableCell align="center" className="ui-job-failure">
              {t("Engine job failure")}
            </TableCell>
            <TableCell align="center" className="ui-negative-feedback">
              {t("Negative user feedback")}
            </TableCell>
            <TableCell align="center" className="ui-positive-feedback">
              {t("Positive user feedback")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((admin, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <Gravatar
                  email={admin.email}
                  size={40}
                  style={{ borderRadius: 20 }}
                ></Gravatar>
              </TableCell>
              <TableCell component="th" scope="row" padding="none">
                <UserNameAndEmail name={admin.name} email={admin.email} />
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                <AdminSettingCheckbox
                  admin={admin}
                  setting="new_user_registered"
                />
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                <AdminSettingCheckbox admin={admin} setting="job_failure" />
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                <AdminSettingCheckbox
                  admin={admin}
                  setting="negative_feedback"
                />
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                <AdminSettingCheckbox
                  admin={admin}
                  setting="positive_feedback"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function NotificationsSettings() {
  const { t } = useTranslation();

  return (
    <MainLayout
      title={t("Notifications")}
      breadcrumbs={[HOME, NOTIFICATIONS_SETTINGS]}
    >
      <NotificationsTutorial />
      <AdminNotificationSettings />
    </MainLayout>
  );
}

export default NotificationsSettings;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactJoyride, { ACTIONS, EVENTS } from "react-joyride";
import {
  useSettings,
  useUpdateSettings,
} from "../../providers/UserProfileProvider";
import { useAppState } from "../../reducers/app";

export default function Tutorial({ steps, section }) {
  const {
    showTutorial,
    tutorials,
    setShowTutorial,
    setcurrentTutorial,
    setCompleteTutorial,
  } = useAppState();
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(0);
  const { disableTutorial } = useSettings();
  const updateSettings = useUpdateSettings();

  const handleJoyrideCallback = ({ action, type, index, step, ...rest }) => {
    if (
      (action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER) ||
      type === EVENTS.TARGET_NOT_FOUND
    ) {
      if (stepIndex == steps.length - 1) {
        setCompleteTutorial(section, true);
        setStepIndex(0);
      } else {
        setStepIndex(stepIndex + 1);
      }

      if (steps[index].after) steps[index].after(step);
    }

    if (action === ACTIONS.PREV && type === EVENTS.STEP_AFTER) {
      setStepIndex(stepIndex - 1);
      if (steps[index].before) steps[index].before(step);
    }

    if (
      action === ACTIONS.START &&
      (type === EVENTS.TOUR_STATUS || type === EVENTS.TOUR_START)
    ) {
      if (steps[index].before) steps[index].before(step);
    }

    if (
      (action === ACTIONS.UPDATE && type === EVENTS.BEACON) ||
      action == ACTIONS.CLOSE
    ) {
      // This explicitly stops the tour (otherwise it displays a "beacon" to resume the tour)
      // Timeout to avoid "Can't perform a React state update on an unmounted component" warning
      setTimeout(() => setShowTutorial(false), 50);
    } else if (action == ACTIONS.SKIP && type == EVENTS.TOUR_END) {
      // Skip button pressed
      updateSettings({ disableTutorial: true });
    }
  };

  // Turn on 'showTutorial' (one in 3 conditions to actually show the tutorial) upon loading component (which typically means we've navigated from one page to another)
  useEffect(() => {
    setcurrentTutorial(section);
    setShowTutorial(true);
    return () => setcurrentTutorial(null);
  }, []);

  return (
    <ReactJoyride
      key={stepIndex} // force rerender of the component when step changes
      run={showTutorial && !disableTutorial && !tutorials[section]?.completed}
      continuous
      steps={steps}
      stepIndex={stepIndex}
      callback={handleJoyrideCallback}
      showSkipButton
      locale={{
        back: t("Back"),
        next: t("Next"),
        last: t("Done"),
        skip: t("Quit tutorial"),
        close: t("Close"),
      }}
      disableOverlayClose
      styles={{
        options: { width: "480px", primaryColor: "#1976d2", zIndex: 1200 },
      }}
    />
  );
}

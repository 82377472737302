import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useOrganizationApi } from "../../providers/OrganizationApiProvider";

export const QUOTA_CROWN_GENERATION = "crown_generation";

function Quotas({ quotas, onChange }) {
  const { t } = useTranslation();
  const coerce = (value) => (value === undefined ? null : value);
  return (
    <Grid container direction={"column"}>
      {[
        ["hourly", t("Hourly")],
        ["daily", t("Daily")],
        ["monthly", t("Monthly")],
      ].map(([key, label]) => (
        <Grid item key={key}>
          <QuotaComponent
            label={label}
            value={coerce(quotas?.[key])}
            onChange={(newValue) => onChange(key, newValue)}
          ></QuotaComponent>
        </Grid>
      ))}
    </Grid>
  );
}

function QuotaComponent({ label, value, onChange }) {
  const { t } = useTranslation();
  const unlimited = value === null;
  const [limit, setLimit] = useState(value);
  const handleValue = (value) => {
    value = parseInt(value); // can be NaN! (if value is "")
    value = value < 0 ? 0 : value; // coerce to positive value
    setLimit(value);
    onChange(value);
  };
  return (
    <Grid container alignItems="center">
      <Grid item xs={3}>
        <FormLabel component="legend">{label}</FormLabel>
      </Grid>
      <Grid item>
        <RadioGroup
          onChange={(e) =>
            onChange(e.target.value === "unlimited" ? null : limit ?? NaN)
          }
          value={unlimited ? "unlimited" : "limited"}
        >
          <FormControlLabel
            value="unlimited"
            control={<Radio />}
            label={t("Unlimited")}
          />
          <FormControlLabel
            value="limited"
            control={<Radio />}
            label={
              <TextField
                type="number"
                label={t("Limit")}
                value={Number.isNaN(limit) || limit === null ? "" : limit}
                onChange={(e) => handleValue(e.target.value)}
                disabled={unlimited}
                size="small"
              />
            }
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

export default function QuotasDialog({ onClose }) {
  const { organization, updateOrganization } = useOrganizationApi();
  const [modifiedQuotas, setModifiedQuotas] = useState({});
  const { t } = useTranslation();

  const quotas = {
    ...organization.quotas?.[QUOTA_CROWN_GENERATION],
    ...modifiedQuotas,
  };

  const handleOk = () => {
    const patch = Object.fromEntries(
      Object.entries(modifiedQuotas).map(([k, v]) => [
        `quotas.${QUOTA_CROWN_GENERATION}.${k}`,
        v,
      ])
    );

    updateOrganization(patch).then(() => onClose());
  };

  const handleChange = (period, newValue) => {
    setModifiedQuotas({ ...modifiedQuotas, [period]: newValue });
  };

  return (
    <Dialog onClose={onClose} open={true}>
      <DialogTitle>{t("Crown Generation Quotas")}</DialogTitle>
      <DialogContent sx={{ minWidth: "480px" }}>
        <Quotas quotas={quotas} onChange={handleChange} />
        <DialogActions>
          <Button onClick={onClose}>{t("Cancel")}</Button>
          <Button
            onClick={handleOk}
            variant="contained"
            disabled={Object.values(quotas).some((v) => Number.isNaN(v))}
          >
            {t("Ok")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

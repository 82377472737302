import { Alert, Box, Paper, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Breadcrumbs, HOME, SURVEY } from "../Components/system/Breadcrumbs";
import Copyright from "../Components/system/Copyright";
import NavBar from "../Components/system/NavBar";
import UserProfileProvider from "../providers/UserProfileProvider";

export default function FeedbackSurvey() {
  return (
    <UserProfileProvider>
      <NavBar />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Breadcrumbs levels={[HOME, SURVEY]} />
        <Paper sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}>
          <Typography variant="h4" align="center">
            Feedback and Satisfaction Survey
          </Typography>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="20rem"
          >
            <Alert
              severity="info"
              sx={{
                width: "500px",
              }}
            >
              The survey is not ready yet. Thank you nonetheless for you
              interest! Please come and try again later.
            </Alert>
          </Box>
        </Paper>
        <Copyright />
      </Container>
    </UserProfileProvider>
  );
}

import { useTranslation } from "react-i18next";
import OrganizationList from "../Components/Organizations/OrganizationList";
import { HOME, ORGANIZATIONS } from "../Components/system/Breadcrumbs";
import OrganizationsTutorial from "../Components/tutorial/OrganizationsTutorial";
import MainLayout from "../layout/MainLayout";

function Organizations() {
  const { t } = useTranslation();
  return (
    <MainLayout title={t("Organizations")} breadcrumbs={[HOME, ORGANIZATIONS]}>
      <OrganizationsTutorial />
      <OrganizationList />
    </MainLayout>
  );
}

export default Organizations;

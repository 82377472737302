import { MoreVertOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import Gravatar from "react-gravatar";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { useGetUsersQuery, useUpdateUserMutation } from "../../services/users";
import OrganizationLinkLabel from "../Organizations/OrganizationLinkLabel";
import Timestamp from "./Timestamp";
import UserNameAndEmail from "./UserAndEmail";
import LoadingError from "./LoadingError";
import Loading from "../Loading";

function UserActivity({ user }) {
  const { t } = useTranslation();
  return (
    <>
      <Box variant="inherit" whiteSpace="nowrap">
        {t("Last login")}
        {":"}&nbsp;
        {user.lastLogin ? (
          <Timestamp time={new Date(user.lastLogin)} />
        ) : (
          t("(unknown)")
        )}
      </Box>

      <Box whiteSpace="nowrap">
        {t("Registered")}
        {":"}&nbsp;
        {user.firstLogin ? (
          <Timestamp time={new Date(user.firstLogin)} />
        ) : (
          t("(unknown)")
        )}
      </Box>
    </>
  );
}

function UserMenu({ id, isExpert }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateUser] = useUpdateUserMutation();
  const { showAlert } = useSnackbar();

  const updateIsExpert = () => {
    updateUser({ id, patch: { isExpert: !isExpert } })
      .unwrap()
      .catch((e) => {
        showAlert(t("Failed to save"));
        throw e;
      });
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        sx={{ marginRight: "10px" }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={updateIsExpert}>
          {isExpert ? t("Unpromote to normal") : t("Promote to expert")}
        </MenuItem>
      </Menu>
    </>
  );
}

export default function UsersTable() {
  const { data, error, isLoading } = useGetUsersQuery();
  const { t } = useTranslation();

  if (isLoading) return <Loading />;
  else if (error) return <LoadingError error={error} />;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "0%" }}></TableCell>
            <TableCell padding="none">{t("ID")}</TableCell>
            <TableCell>{t("Organization")}</TableCell>
            <TableCell>{t("Activity")}</TableCell>
            <TableCell>{t("Role")}</TableCell>
            <TableCell style={{ width: "0%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((user, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <Gravatar
                  email={user.email}
                  size={40}
                  style={{ borderRadius: 20 }}
                ></Gravatar>
              </TableCell>
              <TableCell component="th" scope="row" padding="none">
                <UserNameAndEmail name={user.name} email={user.email} />
              </TableCell>
              <TableCell component="th" scope="row">
                <OrganizationLinkLabel
                  id={user.organization}
                  name={user.organizationName}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <UserActivity user={user} />
              </TableCell>
              <TableCell component="th" scope="row">
                {user.isExpert ? t("Expert") : t("Normal")}
              </TableCell>
              <TableCell component="th" scope="row" padding="none">
                <UserMenu id={user.sub} isExpert={user.isExpert}></UserMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

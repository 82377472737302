import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import DateFilter from "./DateFilter";
import FeedbackFilter from "./FeedbackFilter";
import ProjectRefNumberFilter from "./ProjectRefNumberFilter";
import ProjectStateFilter from "./ProjectStateFilter";
import ProjectTeethFilter from "./ProjectTeethFilter";
import TableHeaderCell from "./TableHeaderCell";

export default function ProjectListHeader(props) {
  const { order, orderBy, onRequestSort, columns, filters, setFilters } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const buildMenu = (column) =>
    column === "state" ? (
      <ProjectStateFilter
        filter={filters.state}
        setFilter={(value) => setFilters(filters.newWith({ state: value }))}
      ></ProjectStateFilter>
    ) : column === "refNumber" ? (
      <ProjectRefNumberFilter
        filter={filters.refNumber}
        setFilter={(value) => setFilters(filters.newWith({ refNumber: value }))}
      ></ProjectRefNumberFilter>
    ) : column === "createdAt" ? (
      <DateFilter
        filter={filters.createdAt}
        setFilter={(value) => setFilters(filters.newWith({ createdAt: value }))}
      ></DateFilter>
    ) : column === "updatedAt" ? (
      <DateFilter
        filter={filters.updatedAt}
        setFilter={(value) => setFilters(filters.newWith({ updatedAt: value }))}
      ></DateFilter>
    ) : column === "teethUnderRepair" ? (
      <ProjectTeethFilter
        filter={filters.teethUnderRepair}
        setFilter={(value) =>
          setFilters(filters.newWith({ teethUnderRepair: value }))
        }
      ></ProjectTeethFilter>
    ) : column === "feedback" ? (
      <FeedbackFilter
        filter={filters.feedback}
        setFilter={(value) => setFilters(filters.newWith({ feedback: value }))}
      ></FeedbackFilter>
    ) : null;

  return (
    <TableHead className="ui-tableHeader">
      <TableRow>
        <TableCell padding="checkbox" />
        {columns.map((headCell, index) => (
          <TableHeaderCell
            key={headCell.id}
            first={index === 0}
            headCell={headCell}
            orderBy={orderBy}
            order={order}
            onClick={createSortHandler(headCell.id)}
            filters={filters}
            menu={buildMenu(headCell.id)}
          ></TableHeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ProjectListHeader.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

import _ from "lodash";
import ProjectState from "./ProjectState";

const defaultStateFilter = () =>
  new Set([
    ProjectState.NEW,
    ProjectState.SCANS,
    ProjectState.CROWN_GENERATION,
    ProjectState.COMPLETE,
    ProjectState.ERROR,
  ]);

const parseDateRange = (str) =>
  str
    ? (([begin, end]) => ({
        ...(begin && { begin }),
        ...(end && { end }),
      }))(str.split("-").map((x) => (x ? new Date(parseInt(x)) : null)))
    : {};

const parseArray = (str) =>
  str
    ? str.split(",").filter((x) => x !== "") // note that "".split(",") returns [""]
    : [];

const parseIntSet = (str) =>
  new Set(
    str
      ? str
          .split(",")
          .filter((x) => x !== "") // note that "".split(",") returns [""]
          .map((x) => parseInt(x))
      : []
  );
export default class ProjectsFilter {
  constructor(obj) {
    const data = obj || {
      refNumber: "",
      state: defaultStateFilter(),
      createdAt: {},
      updatedAt: {},
      teethUnderRepair: [],
      feedback: new Set(),
    };

    Object.defineProperties(this, {
      refNumber: {
        get: () => data.refNumber,
        enumerable: true,
      },
      state: {
        get: () => data.state,
        enumerable: true,
      },
      createdAt: {
        get: () => data.createdAt,
        enumerable: true,
      },
      updatedAt: {
        get: () => data.updatedAt,
        enumerable: true,
      },
      teethUnderRepair: {
        get: () => data.teethUnderRepair,
        enumerable: true,
      },
      feedback: {
        get: () => data.feedback,
        enumerable: true,
      },
      raw: {
        get: () => ({ ...data }),
      },
    });
  }

  hasRefNumberCustomization() {
    return this.refNumber !== "";
  }

  hasStateCustomization() {
    return !_.isEqual(this.state, defaultStateFilter());
  }

  hasCreatedAtCustomization() {
    return !_.isEqual(this.createdAt, {});
  }

  hasUpdatedAtCustomization() {
    return !_.isEqual(this.updatedAt, {});
  }

  hasTeethUnderRepairCustomization() {
    return !_.isEqual(this.teethUnderRepair, []);
  }

  hasFeedbackCustomization() {
    return !_.isEqual(this.feedback, new Set());
  }

  hasCustomization(prop) {
    prop = prop.charAt(0).toUpperCase() + prop.slice(1);
    const getter = this[`has${prop}Customization`];
    return getter && getter.call(this);
  }

  hasCustomizations() {
    return (
      this.hasRefNumberCustomization() ||
      this.hasStateCustomization() ||
      this.hasCreatedAtCustomization() ||
      this.hasUpdatedAtCustomization() ||
      this.hasTeethUnderRepairCustomization() ||
      this.hasFeedbackCustomization()
    );
  }

  newWith(obj) {
    return new ProjectsFilter({ ...this.raw, ...obj });
  }

  clear() {
    return new ProjectsFilter();
  }

  matches(project) {
    return (
      this.state.has(project.hasError() ? ProjectState.ERROR : project.state) &&
      (project.refNumber || "")
        .toLowerCase()
        .includes(this.refNumber.toLowerCase()) &&
      (!this.createdAt.begin || this.createdAt.begin <= project.createdAt) &&
      (!this.createdAt.end || this.createdAt.end >= project.createdAt) &&
      (!this.updatedAt.begin || this.updatedAt.begin <= project.updatedAt) &&
      (!this.updatedAt.end || this.updatedAt.end >= project.updatedAt) &&
      (this.teethUnderRepair.length === 0 ||
        project.teethUnderRepair.some((t) =>
          this.teethUnderRepair.includes(t)
        )) &&
      (this.feedback.size === 0 || this.feedback.has(project.feedback || 0))
    );
  }

  toQueryString() {
    const toStr = (date) => date?.getTime() ?? "";

    const params = new URLSearchParams();
    if (this.hasRefNumberCustomization())
      params.set("refNumber", this.refNumber);
    if (this.hasStateCustomization())
      params.set("state", [...this.state].map((s) => s.name).join(","));
    if (this.hasCreatedAtCustomization())
      params.set(
        "createdAt",
        toStr(this.createdAt.begin) + "-" + toStr(this.createdAt.end)
      );
    if (this.hasUpdatedAtCustomization())
      params.set(
        "updatedAt",
        toStr(this.updatedAt.begin) + "-" + toStr(this.updatedAt.end)
      );
    if (this.hasTeethUnderRepairCustomization())
      params.set("teethUnderRepair", this.teethUnderRepair.join(","));
    if (this.hasFeedbackCustomization())
      params.set("feedback", Array.from(this.feedback).join(","));
    return params;
  }

  static fromQueryString(str) {
    const params = new URLSearchParams(str);
    const filter = new ProjectsFilter();
    return filter.newWith({
      refNumber: params.get("refNumber") || "",
      ...(params.has("state")
        ? {
            state: new Set(
              parseArray(params.get("state")).map((x) =>
                ProjectState.valueOf(x)
              )
            ),
          }
        : null),
      createdAt: parseDateRange(params.get("createdAt")),
      updatedAt: parseDateRange(params.get("updatedAt")),
      teethUnderRepair: parseArray(params.get("teethUnderRepair")).map((x) =>
        parseInt(x)
      ),
      feedback: parseIntSet(params.get("feedback")),
    });
  }
}

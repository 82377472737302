import { useTranslation } from "react-i18next";
import ProjectHistoryTable from "../Components/project/ProjectHistoryTable";
import ProjectSubpage from "../Components/project/ProjectSubpage";
import { PROJECT_HISTORY } from "../Components/system/Breadcrumbs";

export default function ProjectHistory() {
  const { t } = useTranslation();
  return (
    <ProjectSubpage title={t("Project History")} crumb={PROJECT_HISTORY}>
      <ProjectHistoryTable />
    </ProjectSubpage>
  );
}

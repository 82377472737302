import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useGetWorkerImageVersionsQuery } from "../../services/systemSettings";
import Loading from "../Loading";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

const sortVersions = (a, b) =>
  a.startsWith("latest") ? -1 : b.startsWith("latest") ? 1 : a.localeCompare(b);

export default function WorkerVersions({ currentVersions, onVersionsChange }) {
  let {
    data: workerVersions,
    isLoading,
    error,
  } = useGetWorkerImageVersionsQuery();
  const { t } = useTranslation();
  const classes = useStyles();

  if (isLoading) return <Loading />;
  else if (error) return <LoadingError error={error} />;

  const VersionSelect = ({ worker, imageName, values }) => {
    const versions = [...workerVersions[imageName]].sort(sortVersions);
    const value = values[imageName];
    return (
      <FormControl>
        <InputLabel>{worker}</InputLabel>
        <Select
          className={classes.formControl}
          value={value || "latest"}
          onChange={(event) => onVersionsChange(imageName, event.target.value)}
        >
          {/* If current version does not exist, add it as a readonly option */}
          {!versions.includes(value) && (
            <MenuItem disabled={true} value={value}>
              {value}
            </MenuItem>
          )}
          {versions.map((v) => (
            <MenuItem key={v} value={v}>
              {v.replace(/^latest/, t("Latest"))}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Grid container direction={"column"} spacing={2} padding={1}>
      <Grid item>
        <VersionSelect
          worker={t("Tooth Labeling")}
          imageName="segmentation"
          values={currentVersions}
        />
      </Grid>
      <Grid item>
        <VersionSelect
          worker={t("Margin Lines")}
          imageName="marginline"
          values={currentVersions}
        />
      </Grid>
      <Grid item>
        <VersionSelect
          worker={t("Crown Generation")}
          imageName="crown-generation-pointr"
          values={currentVersions}
        />
      </Grid>
    </Grid>
  );
}

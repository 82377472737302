import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import ProjectState from "../../model/ProjectState";
import ProjectStateLabel from "./ProjectStateLabel";
import { useProfile } from "../../providers/UserProfileProvider";

export default function ProjectStateFilter({ filter, setFilter }) {
  const { isAdmin } = useProfile();

  const setEnabled = (state, enabled) => {
    const newFilter = new Set(filter);
    if (enabled) newFilter.add(state);
    else newFilter.delete(state);
    setFilter(newFilter);
  };

  let states = Object.values(ProjectState);
  if (!isAdmin) {
    states = states.filter((state) => state != ProjectState.ARCHIVED);
  }

  return (
    <FormGroup sx={{ p: 2 }}>
      {states.map((state, index) => (
        <FormControlLabel
          key={index}
          control={<Switch checked={filter.has(state)} />}
          label={<ProjectStateLabel state={state} />}
          onChange={(event) => setEnabled(state, event.target.checked)}
        />
      ))}
    </FormGroup>
  );
}

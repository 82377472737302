import { FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";
import MultiToothPicker from "../widgets/MultiToothPicker";

export default function ProjectTeethFilter({ filter, setFilter }) {
  const { t } = useTranslation();
  return (
    <FormControl sx={{ p: 2, maxWidth: "600px" }}>
      <MultiToothPicker
        selectedTeeth={filter}
        onChange={setFilter}
        label={t("Teeth")}
        showClear
      ></MultiToothPicker>
    </FormControl>
  );
}

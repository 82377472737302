import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import { useSettings } from "../../providers/UserProfileProvider";
import { useGetProjectHistoryQuery } from "../../services/projects";
import { getToothLabel } from "../../utils/ToothNotation";
import ProjectStateLabel from "../projects/ProjectStateLabel";
import ProjectState from "../../model/ProjectState";
import FeedbackLabel from "../projects/FeedbackLabel";
import LoadingError from "../system/LoadingError";
import Loading from "../Loading";
import UserLabel from "../system/UserLabel";

function renderEventName(event) {
  const { t } = useTranslation();
  switch (event) {
    case "created":
      return t("Project created");
    case "file uploaded":
      return t("File upload");
    case "segmentation":
      return t("Tooth labeling");
    case "marginline":
      return t("Margin line");
    case "crownGeneration":
      return t("Crown generation");
    case "userModification":
      return t("Modification");
    default:
      return event;
  }
}

function renderFileUploadedEvent({ id }) {
  const { t } = useTranslation();
  return {
    [t("Object")]:
      id === "upper"
        ? t("Upper arch")
        : id === "lower"
        ? t("Lower arch")
        : t("#{{tooth}} die", { tooth: id }),
  };
}

function renderWorkerJob({ status, version, duration, cause }) {
  const { t, i18n } = useTranslation();
  const statusLabel =
    status == "success" ? "Success" : status === "failed" ? "Error" : "Unknown";
  return {
    [t("Status")]: t(statusLabel),
    [t("Version")]: version,
    [t("Duration")]: duration.toLocaleString(i18n.language, {
      maximumFractionDigits: 1,
      style: "unit",
      unit: "second",
    }),
    ...(cause ? { [t("Cause")]: t("jobErrorCodes." + cause) } : null),
  };
}

function renderUserModification({ user, data }) {
  const { t } = useTranslation();
  const { notation } = useSettings();

  const convert = ([key, value]) => {
    if (key == "teethUnderRepair") {
      return [
        t("Prepped teeth"),
        data.teethUnderRepair.map((t) => getToothLabel(t, notation)).join(", "),
      ];
    } else if (key == "state") {
      return [
        t("State"),
        data.state ? (
          <ProjectStateLabel state={ProjectState.valueOf(data.state)} />
        ) : (
          t("Unknown")
        ),
      ];
    } else if (key == "previousState") {
      // legacy stuff
      return null;
    } else if (key.endsWith(".file") || key.endsWith(".dieFile")) {
      return null;
    } else if (key == "refNumber") {
      return [t("Reference Number (e.g. Case ID)"), data.refNumber];
    } else if (key == "upperArch.start") {
      return [
        t("Upper arch") + " - " + t("First tooth"),
        getToothLabel(data["upperArch.start"], notation),
      ];
    } else if (key == "upperArch.end") {
      return [
        t("Upper arch") + " - " + t("Last tooth"),
        getToothLabel(data["upperArch.end"], notation),
      ];
    } else if (key == "lowerArch.start") {
      return [
        t("Lower arch") + " - " + t("First tooth"),
        getToothLabel(data["lowerArch.start"], notation),
      ];
    } else if (key == "lowerArch.end") {
      return [
        t("Lower arch") + " - " + t("Last tooth"),
        getToothLabel(data["lowerArch.end"], notation),
      ];
    } else if (key == "feedback") {
      return [
        t("Feedback"),
        <FeedbackLabel value={data.feedback} compact={true} />,
      ];
    } else if (key == "feedbackComment") {
      return [t("Comment"), data.feedbackComment];
    } else if (key.startsWith("workerVersions.")) {
      const worker = key.split(".")[1];
      const label =
        worker === "marginline"
          ? t("Margin line")
          : worker.startsWith("crown-generation")
          ? t("Crown generation")
          : worker === "segmentation"
          ? t("Tooth labeling")
          : t("Unknown");

      return [
        t("AI Engine Versions") + " - " + label,
        data[key].replace(/^latest/, t("Latest")),
      ];
    } else if (key.match(/toothRestorations\.\d+\.toothTemplate/)) {
      const tooth = parseInt(key.split(".")[1]);
      return [
        t("Design tooth #{{tooth}} as", {
          tooth: getToothLabel(tooth, notation),
        }),
        getToothLabel(data[key], notation),
      ];
    } else {
      return [key, JSON.stringify(value)];
    }
  };

  return Object.fromEntries(
    Object.entries(data)
      .map(convert)
      .filter((x) => !!x)
  );
}

function EventDetails({ event, data }) {
  const { i18n } = useTranslation();

  // First, cleanup data
  switch (event) {
    case "file uploaded":
      data = renderFileUploadedEvent(data);
      break;
    case "segmentation":
    case "marginline":
    case "crownGeneration":
      data = renderWorkerJob(data);
      break;
    case "userModification":
      data = renderUserModification(data);
      break;
    default:
      data = Object.fromEntries(
        Object.entries(data).map(([k, v]) => [k, JSON.stringify(v)])
      );
  }

  return (
    <Grid container>
      {Object.entries(data).map(([key, value]) => (
        <Grid item container key={key} spacing={1}>
          <Grid item>
            <strong>{key}:</strong>
          </Grid>
          <Grid item>{value}</Grid>
        </Grid>
      ))}
    </Grid>
  );
}

function HistoryTable({ rows }) {
  const { t, i18n } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("Event")}</TableCell>
            <TableCell>{t("Time")}</TableCell>
            <TableCell>{t("User")}</TableCell>
            <TableCell>{t("Details")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ event, timestamp, user, ...details }, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {renderEventName(event)}
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                {new Date(timestamp).toLocaleString(i18n.language)}
              </TableCell>
              <TableCell width={"15%"}>
                {user && <UserLabel id={user} />}
              </TableCell>
              <TableCell>
                <EventDetails event={event} data={details}></EventDetails>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default function ProjectHistoryTable() {
  const { t } = useTranslation();
  const projectId = useProjectApi().project.id;
  const { data, error, isLoading } = useGetProjectHistoryQuery(projectId);

  if (isLoading) return <Loading />;
  else if (error) return <LoadingError error={error} />;

  return <HistoryTable rows={data}></HistoryTable>;
}

import { Box, TextField, Typography } from "@mui/material";
import { useEffect, useRef } from "react";

export default function EditableText({
  edit,
  text,
  onClick,
  onDoneEditing,
  variant,
}) {
  const inputRef = useRef();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") commit();
    else if (e.key === "Escape") onDoneEditing(null);
  };

  function commit() {
    onDoneEditing(
      inputRef.current.value != text ? inputRef.current.value : null
    );
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [edit]);

  return (
    <Box display="flex" justifyContent={"center"}>
      {edit ? (
        <TextField
          onBlur={commit}
          defaultValue={text}
          inputRef={inputRef}
          sx={{ width: "75%" }}
          onKeyDown={handleKeyDown}
        ></TextField>
      ) : (
        <div onClick={onClick}>
          <Typography variant={variant} sx={{ display: "inline" }}>
            {text}
          </Typography>
        </div>
      )}
    </Box>
  );
}

EditableText.defaultProps = {
  variant: "body1",
};

import { useParams } from "react-router-dom";
import ProjectWorflow from "../Components/project/ProjectWorkflow";
import {
  HOME,
  ORGANIZATION,
  ORGANIZATIONS,
  PROJECT,
  PROJECTS,
} from "../Components/system/Breadcrumbs";
import MainLayout from "../layout/MainLayout";
import OrganizationApiProvider from "../providers/OrganizationApiProvider";
import { withProjectApiProvider } from "../providers/ProjectApiProvider";
import { useProfile } from "../providers/UserProfileProvider";

function Project() {
  const { organizationId } = useParams();
  const { isAdmin } = useProfile();

  const breadcrumbs = () => [
    HOME,
    ...(organizationId
      ? [...(isAdmin ? [ORGANIZATIONS] : []), ORGANIZATION]
      : []),
    PROJECTS,
    PROJECT,
  ];

  return (
    <>
      {organizationId ? (
        <MainLayout title={null} breadcrumbs={breadcrumbs()}>
          <OrganizationApiProvider>
            <ProjectWorflow />
          </OrganizationApiProvider>
        </MainLayout>
      ) : (
        <MainLayout title={null} breadcrumbs={breadcrumbs()}>
          <ProjectWorflow />
        </MainLayout>
      )}
    </>
  );
}

export default withProjectApiProvider(Project);

import { Backdrop, CircularProgress } from "@mui/material";
import { createContext, useContext, useState } from "react";

const backdropContext = createContext(null);

export const useBackdrop = () => useContext(backdropContext);

const BackdropProvider = (props) => {
  const [showBackdrop, setShowBackdrop] = useState(false);

  return (
    <backdropContext.Provider value={{ setShowBackdrop }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {props.children}
    </backdropContext.Provider>
  );
};

export default BackdropProvider;

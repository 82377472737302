import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { HOME, SYSTEM_SETTINGS } from "../Components/system/Breadcrumbs";
import WorkerVersions from "../Components/system/WorkerVersions";
import MainLayout from "../layout/MainLayout";
import {
  useGetSystemSettingsQuery,
  useUpdateSystemSettingsMutation,
} from "../services/systemSettings";
import LoadingError from "../Components/system/LoadingError";
import Loading from "../Components/Loading";

function SystemSettingsComponent() {
  const { data, isLoading, error } = useGetSystemSettingsQuery();
  const [updateSettings] = useUpdateSystemSettingsMutation();
  const { t } = useTranslation();

  if (isLoading) return <Loading />;
  else if (error) return <LoadingError error={error} />;

  const currentVersions = data?.defaultWorkerVersions || {};
  const onVersionsChange = (imageName, newVersion) =>
    updateSettings({
      ["defaultWorkerVersions." + imageName]: newVersion,
    });

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h5">
            {t("Default AI engine versions")}
          </Typography>
        </Grid>
        <Grid item xs>
          <WorkerVersions
            currentVersions={currentVersions}
            onVersionsChange={onVersionsChange}
          />
        </Grid>
      </Grid>
    </>
  );
}

function SystemSettings() {
  const { t } = useTranslation();

  return (
    <MainLayout
      title={t("System Settings")}
      breadcrumbs={[HOME, SYSTEM_SETTINGS]}
    >
      <SystemSettingsComponent />
    </MainLayout>
  );
}

export default SystemSettings;

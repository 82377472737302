import { MoreVert } from "@mui/icons-material";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import useRegenerateCurrentProjectPrompt from "../../hooks/useRegenerateCurrentProjectPrompt";
import ProjectState from "../../model/ProjectState";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import { useProfile } from "../../providers/UserProfileProvider";
import WorkerVersionDialog from "./WorkerVersionDialog";

export default function ProjectMenu() {
  const { project } = useProjectApi();
  const { isExpert } = useProfile();
  const location = useLocation();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [showWorkerVersionsDialog, setShowWorkerVersionsDialog] =
    useState(false);

  const handleRegenerate = useRegenerateCurrentProjectPrompt();
  const labellingUrl = `/projects/${project.id}/labelling`;
  const historyUrl = `/projects/${project.id}/history`;

  return (
    <>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className="ui-project-menu"
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => setAnchorEl(null)}
      >
        {isExpert && (
          <MenuItem
            component={Link}
            to={labellingUrl}
            disabled={
              project.state == ProjectState.NEW ||
              location.pathname === labellingUrl
            }
          >
            {t("Tooth Labeling")}
          </MenuItem>
        )}
        <MenuItem
          component={Link}
          to={historyUrl}
          disabled={location.pathname === historyUrl}
        >
          {t("History")}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleRegenerate();
            setAnchorEl(null);
          }}
          disabled={
            project.state == ProjectState.NEW ||
            project.state == ProjectState.SCANS
          }
        >
          {t("Regenerate project...")}
        </MenuItem>
        {isExpert && <Divider />}
        {isExpert && (
          <MenuItem
            onClick={() => {
              setShowWorkerVersionsDialog(true);
              setAnchorEl(null);
            }}
          >
            {t("AI engine version...")}
          </MenuItem>
        )}
      </Menu>

      {showWorkerVersionsDialog && (
        <WorkerVersionDialog
          onClose={() => setShowWorkerVersionsDialog(false)}
        />
      )}
    </>
  );
}

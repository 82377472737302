import { Done } from "@mui/icons-material";
import { Box, Grid, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProjectState from "../../model/ProjectState";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import { useSettings } from "../../providers/UserProfileProvider";
import { mediastoreUrl } from "../../services/FileService";
import { getToothLabel } from "../../utils/ToothNotation";
import ArchScansTutorial from "../tutorial/ArchScansTutorial";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../widgets/Accordion";
import ArchExtentsPicker from "../widgets/ArchExtentsPicker";
import MeshViewer from "../widgets/MeshViewer";
import ArchRange from "./ArchRange";
import DieInputs from "./DieInputs";
import FileUpload from "./FileUpload";

export default function ArchScans() {
  const { notation } = useSettings();
  const { showConfirmationDialog } = useConfirmationDialog();
  const { t } = useTranslation();

  const {
    project: {
      id: projectId,
      state,
      teethUnderRepair,
      upperArch,
      lowerArch,
      toothRestorations,
    },
    saveProject,
  } = useProjectApi();

  const checkAndConfirmProjectRollback = async () => {
    return (
      state === ProjectState.SCANS ||
      (await showConfirmationDialog({
        title: t("projects.actions.modifyGeneratedProject.title"),
        message: (
          <>
            <p>{t("projects.actions.modifyGeneratedProject.paragraph1")}</p>
            <p>{t("projects.actions.modifyGeneratedProject.paragraph2")}</p>
          </>
        ),
        canDismiss: false,
      }))
    );
  };

  const updateArch = (name) => async (arch) => {
    if (await checkAndConfirmProjectRollback())
      saveProject({
        [`${name}Arch.start`]: arch.start,
        [`${name}Arch.end`]: arch.end,
        state: ProjectState.SCANS,
      });
  };

  const isReady = (arch) =>
    arch.isReady() && arch.coversAllApplicableTeeth(teethUnderRepair);

  const [expanded, setExpanded] = useState(
    isReady(upperArch)
      ? isReady(lowerArch)
        ? Object.values(toothRestorations).every((t) => t.dieFile.isDecimated())
          ? ""
          : "dies"
        : "lower"
      : "upper"
  );

  const expandSection = (section) => {
    if (!expanded.includes(section)) setExpanded(expanded + "," + section);
  };

  const handleChange = (section) => (event, newExpanded) => {
    if (newExpanded) setExpanded("," + section);
    else setExpanded("");
  };

  function buildFilesToShow() {
    return [].concat(
      upperArch.file.isDecimated()
        ? [
            {
              name: mediastoreUrl(
                projectId,
                upperArch.file.decimatedFileName()
              ),
              show: true,
              highlight: expanded.includes("upper"),
            },
          ]
        : [],
      lowerArch.file.isDecimated()
        ? [
            {
              name: mediastoreUrl(
                projectId,
                lowerArch.file.decimatedFileName()
              ),
              show: true,
              highlight: expanded.includes("lower"),
            },
          ]
        : [],
      Object.values(toothRestorations)
        .map((t) => t.dieFile)
        .filter((f) => f.isDecimated())
        .map((f) => ({
          name: mediastoreUrl(projectId, f.decimatedFileName()),
          show: true,
          highlight: expanded.includes("dies"),
        }))
    );
  }

  const requiredArch = (arch) =>
    teethUnderRepair.some((t) => arch.teeth().includes(t)) ? (
      <Tooltip title={t("Master arch is required")}>
        <sup> *</sup>
      </Tooltip>
    ) : (
      <sup> {t("antagonist")}</sup>
    );

  return (
    <Grid container spacing={3} justifyContent="center">
      <ArchScansTutorial expandSection={expandSection} />
      <Grid item xs>
        <Box minWidth={"350px"}>
          <Accordion
            expanded={expanded.includes("upper")}
            onChange={handleChange("upper")}
          >
            <AccordionSummary>
              <Box sx={{ marginRight: "auto" }}>
                <Typography display="inline">{t("Upper arch")}</Typography>
                {requiredArch(upperArch)}
              </Box>
              <ArchRange arch={upperArch} />
              {isReady(upperArch) ? <Done color="success" /> : <></>}
            </AccordionSummary>
            <AccordionDetails>
              <FileUpload
                model={upperArch.file}
                checkAndConfirmProjectRollback={checkAndConfirmProjectRollback}
                maxWidth={"440px"}
              />
              <hr />
              <div className="ui-arch-extents-picker">
                <ArchExtentsPicker
                  arch={upperArch}
                  onChange={updateArch("upper")}
                  jaw="upper"
                  preppedTeeth={teethUnderRepair}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded.includes("lower")}
            onChange={handleChange("lower")}
          >
            <AccordionSummary className="ui-lower-arch-scan-header">
              <Box sx={{ marginRight: "auto" }}>
                <Typography display="inline">{t("Lower arch")}</Typography>
                {requiredArch(lowerArch)}
              </Box>
              <ArchRange arch={lowerArch} />
              {isReady(lowerArch) ? <Done color="success" /> : <></>}
            </AccordionSummary>
            <AccordionDetails>
              <FileUpload
                model={lowerArch.file}
                checkAndConfirmProjectRollback={checkAndConfirmProjectRollback}
                maxWidth={"440px"}
              />
              <hr />
              <ArchExtentsPicker
                arch={lowerArch}
                onChange={updateArch("lower")}
                jaw="lower"
                preppedTeeth={teethUnderRepair}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded.includes("dies")}
            onChange={handleChange("dies")}
          >
            <AccordionSummary>
              <Typography sx={{ marginRight: "auto" }}>{t("Dies")}</Typography>
              <Typography
                sx={{
                  color: "text.secondary",
                  flexShrink: 0,
                  textAlign: "right",
                  marginRight: "10px",
                }}
              >
                {teethUnderRepair
                  .map((t) => getToothLabel(t, notation))
                  .join(", ")}
              </Typography>
              {Object.values(toothRestorations).every((t) =>
                t.dieFile.isDecimated()
              ) ? (
                <Done color="success" />
              ) : (
                <></>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div className="ui-die-files">
                {teethUnderRepair.map((t) => (
                  <DieInputs
                    key={t}
                    toothRestoration={toothRestorations[t]}
                    checkAndConfirmProjectRollback={
                      checkAndConfirmProjectRollback
                    }
                  />
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Grid>
      <Grid item>
        <Box className="ui-scans-preview" justifyItems={"center"}>
          <MeshViewer size="350px" files={buildFilesToShow()} />
        </Box>
      </Grid>
    </Grid>
  );
}

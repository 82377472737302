import { Help } from "@mui/icons-material";
import { Trans, useTranslation } from "react-i18next";
import Tutorial from "../widgets/Tutorial";

export default function HomeTutorial() {
  const { t } = useTranslation(["tutorial"]);

  const steps = [
    {
      target: "body",
      title: t("home.general.title"),
      content: (
        <>
          <p>{t("home.general.paragraph1")}</p>
          <p>{t("home.general.paragraph2")}</p>
          <p>
            <Trans ns={["tutorial"]} i18nKey={"home.general.paragraph3"}>
              text0
              <Help
                color="primary"
                style={{ verticalAlign: "middle" }}
              ></Help>{" "}
              text2
            </Trans>
          </p>
          <p>{t("home.general.paragraph4")}</p>
        </>
      ),
      disableBeacon: true,
      placement: "center",
    },
    {
      target: ".ui-new-project-button",
      content: <p>{t("home.newProject.paragraph1")}</p>,
      placement: "left",
    },
    {
      target: ".ui-link-to-existing-projects",
      content: (
        <>
          <p>{t("home.projects.paragraph1")}</p>
          <p>{t("home.projects.paragraph2")}</p>
        </>
      ),
      placement: "right",
    },
    {
      target: ".ui-home-link",
      content: <p>{t("home.homeLink.paragraph1")}</p>,
      placement: "bottom",
    },
    {
      target: ".ui-organization-link",
      content: (
        <>
          <p>{t("home.organizationLink.paragraph1")}</p>
          <p>{t("home.organizationLink.paragraph2")}</p>
        </>
      ),
      placement: "bottom",
    },
    {
      target: ".ui-help-menu",
      content: <p>{t("home.helpMenu.paragraph1")}</p>,
      placement: "bottom",
    },
    {
      target: ".ui-profile-menu",
      content: (
        <>
          <p>{t("home.profileMenu.paragraph1")}</p>
          <p>{t("home.profileMenu.paragraph2")}</p>
        </>
      ),
      placement: "bottom",
    },
    {
      target: "body",
      content: (
        <>
          <p>{t("home.end.paragraph1")}</p>
          <p>{t("home.end.paragraph2")}</p>
        </>
      ),
      placement: "center",
    },
  ];

  return <Tutorial steps={steps} section="home" />;
}

import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Tutorial from "../widgets/Tutorial";

export default function NotificationsTutorial() {
  const { t } = useTranslation(["tutorial"]);

  const steps = [
    {
      target: "body",
      title: t("notificationSettings.general.title"),
      content: (
        <>
          <p>{t("notificationSettings.general.paragraph1")}</p>
          <p>{t("notificationSettings.general.paragraph2")}</p>
        </>
      ),
      disableBeacon: true,
      placement: "center",
    },
    {
      target: ".ui-admin-column",
      content: (
        <>
          <p>
            <Trans
              ns={["tutorial"]}
              i18nKey="notificationSettings.adminColumn.paragraph1"
            >
              text0
              <Link to="/administrators"></Link>
              text2.
            </Trans>
          </p>
        </>
      ),
      placement: "right",
    },
    {
      target: ".ui-events-column",
      content: (
        <>
          <p>{t("notificationSettings.eventsColumn.paragraph1")}</p>
          <p>{t("notificationSettings.eventsColumn.paragraph2")}</p>
        </>
      ),
      placement: "left",
    },
    {
      target: ".ui-job-failure",
      content: (
        <>
          <p>{t("notificationSettings.jobFailure.paragraph1")}</p>
          <p>{t("notificationSettings.jobFailure.paragraph2")}</p>
        </>
      ),
      placement: "top",
    },
    {
      target: ".ui-negative-feedback",
      content: (
        <>
          <p>{t("notificationSettings.negativeFeedback.paragraph1")}</p>
          <p>{t("notificationSettings.negativeFeedback.paragraph2")}</p>
        </>
      ),
      placement: "top",
    },
    {
      target: ".ui-positive-feedback",
      content: (
        <>
          <p>{t("notificationSettings.positiveFeedback.paragraph1")}</p>
          <p>{t("notificationSettings.positiveFeedback.paragraph2")}</p>
        </>
      ),
      placement: "top",
    },
  ];

  return <Tutorial steps={steps} section="notificationSettings"></Tutorial>;
}

import { useTranslation } from "react-i18next";
import Tutorial from "../widgets/Tutorial";

export default function OrganizationsTutorial() {
  const { t } = useTranslation(["tutorial"]);

  const steps = [
    {
      target: "body",
      title: t("organizations.general.title"),
      content: (
        <>
          <p>{t("organizations.general.paragraph1")}</p>
          <p>{t("organizations.general.paragraph2")}</p>
        </>
      ),
      disableBeacon: true,
      placement: "center",
    },
    {
      target: ".ui-new-organization-button",
      content: (
        <>
          <p>{t("organizations.newOrganization.paragraph1")}</p>
        </>
      ),
      placement: "right",
    },
    {
      target: ".ui-header-name",
      content: (
        <>
          <p>{t("organizations.organizationName.paragraph1")}</p>
          <p>{t("organizations.organizationName.paragraph2")}</p>
        </>
      ),
      placement: "bottom",
    },
    {
      target: ".ui-header-nbMembers",
      content: (
        <>
          <p>{t("organizations.memberCount.paragraph1")}</p>
          <p>{t("organizations.memberCount.paragraph2")}</p>
          <p>{t("organizations.memberCount.paragraph3")}</p>
          <p>{t("organizations.memberCount.paragraph4")}</p>
        </>
      ),
      placement: "bottom",
    },
    {
      target: ".ui-header-nbProjects",
      content: (
        <>
          <p>{t("organizations.projectCount.paragraph1")}</p>
          <p>{t("organizations.projectCount.paragraph2")}</p>
        </>
      ),
      placement: "bottom",
    },
  ];

  return <Tutorial steps={steps} section="organizations"></Tutorial>;
}

import { Link } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { QUOTA_CROWN_GENERATION } from "../Components/Organizations/QuotasDialog";
import config from "../config";
import { useConfirmationDialog } from "../providers/ConfirmationDialogProvider";
import { useLazyGetOrganizationQuery } from "../services/organizations";

export default function useQuotaExceededDialog() {
  const { showConfirmationDialog } = useConfirmationDialog();
  const { t } = useTranslation();
  const [getOrganizationTrigger] = useLazyGetOrganizationQuery();

  return async (orgId) => {
    const organization = await getOrganizationTrigger(orgId).unwrap();

    const quotas = ((quotas) =>
      (quotas = {
        hourly: quotas.hourly ?? t("unlimited"),
        daily: quotas.daily ?? t("unlimited"),
        monthly: quotas.monthly ?? t("unlimited"),
      }))(organization.quotas?.[QUOTA_CROWN_GENERATION] || {});

    await showConfirmationDialog({
      title: t("Quota Exceeded"),
      message: (
        <>
          <p>
            {t("projects.quotaExceeded.paragraph1", {
              quotas,
            })}
          </p>
          <p>
            <Trans i18nKey={"projects.quotaExceeded.paragraph2"}>
              text0
              <Link href={`mailto:${config.adminEmail}`}>text2</Link>
            </Trans>
          </p>
        </>
      ),
      buttons: [t("Ok")],
    });
  };
}

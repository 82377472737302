import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Trans, useTranslation } from "react-i18next";
import ProjectState from "../../model/ProjectState";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import { useSettings } from "../../providers/UserProfileProvider";
import { getToothLabel } from "../../utils/ToothNotation";
import FeedbackWidget from "../widgets/FeedbackWidget";
import CompletedProjectTutorial from "../tutorial/CompletedProjectTutorial";
import FileDownload from "./FileDownload";
import { useState } from "react";

function FeedbackForm() {
  const { project, saveProject } = useProjectApi();
  const { t } = useTranslation();
  const [comment, setComment_] = useState(project.feedbackComment || "");
  const [showComment, setShowComment] = useState(!project.feedbackComment);

  const setComment = _.debounce(setComment_, 150);

  const saveFeedback = (value) => {
    saveProject({
      feedback: value,
    }).then(() => {
      setShowComment(true);
    });
  };

  function submitComment() {
    saveProject({
      feedbackComment: comment,
    }).then(() => setShowComment(false));
  }

  return (
    <>
      <FeedbackWidget value={project.feedback} onChange={saveFeedback} />
      {project.feedback !== null &&
        (showComment ? (
          <Box>
            <Alert color="success" sx={{ mt: "1rem" }}>
              <Trans
                ns={["translation"]}
                i18nKey={"projects.feedbackSurveyRequest"}
              ></Trans>
            </Alert>
            <TextField
              label={t("Comment")}
              fullWidth
              multiline
              defaultValue={project.feedbackComment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Button
              variant="contained"
              size="small"
              color="secondary"
              sx={{
                display: "block",
                marginLeft: "auto",
                marginRight: 0,
              }}
              onClick={submitComment}
              disabled={(project.feedbackComment || "") === comment}
            >
              {t("Submit")}
            </Button>
          </Box>
        ) : (
          <Box>
            <Alert color="success" sx={{ mt: "1rem" }}>
              {t("Thank you!")}
            </Alert>
            <Button
              size="small"
              color="secondary"
              sx={{
                display: "block",
                marginLeft: "auto",
                marginRight: 0,
                textTransform: "none",
              }}
              onClick={() => setShowComment(true)}
            >
              [{t("Edit comment")}]
            </Button>
          </Box>
        ))}
    </>
  );
}

export default function CompletedProject() {
  const { notation } = useSettings();
  const { project } = useProjectApi();
  const { t } = useTranslation();

  return (
    <>
      <CompletedProjectTutorial />
      <Grid container rowSpacing={4}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            {t("Success")}
          </Typography>
          <Typography>{t("Dental design project complete.")}</Typography>
          <List>
            <ListItem>
              <Typography>
                <FileDownload
                  projectId={project.id}
                  files={Object.values(project.toothRestorations).map((t) => ({
                    id: t.crownFileName(),
                    name: `crown${getToothLabel(t.tooth, notation)}.stl`,
                  }))}
                  downloadFilename={`${project.refNumber}.zip`}
                >
                  {t("Generated crowns")}
                </FileDownload>
              </Typography>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6} container spacing={2}>
          <Grid item xs={12} minHeight="270px">
            <Card>
              <CardHeader title={t("projects.feedbackRequest")} />
              <CardContent>
                <FeedbackForm />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} />
      </Grid>
    </>
  );
}

import { Trans, useTranslation } from "react-i18next";
import { useProfile } from "../../providers/UserProfileProvider";
import Tutorial from "../widgets/Tutorial";

export default function OrganizationTutorial() {
  const { isAdmin, organization } = useProfile();
  const { t } = useTranslation(["tutorial"]);

  const usersSteps = [
    {
      target: "body",
      title: t("organization.general.title"),
      content: (
        <>
          <p>{t("organization.general.paragraph1")}</p>
          <p>{t("organization.general.paragraph2")}</p>
        </>
      ),
      disableBeacon: true,
      placement: "center",
    },
  ];

  const managersSteps = [
    {
      target: ".ui-organization-menu",
      content: (
        <>
          <p>{t("organization.menu.paragraph1")}</p>
        </>
      ),
      placement: "bottom",
    },
    {
      target: ".ui-user-picker",
      content: (
        <>
          <p>{t("organization.userPicker.paragraph1")}</p>
          <p>
            <Trans
              ns={["tutorial"]}
              i18nKey={"organization.userPicker.paragraph2"}
            >
              text0<em>text1</em>text2
            </Trans>
          </p>
          <p>{t("organization.userPicker.paragraph3")}</p>
        </>
      ),
      placement: "right",
    },
    {
      target: ".ui-tab-projects",
      content: (
        <>
          <p>{t("organization.projectsTab.paragraph1")}</p>
        </>
      ),
      placement: "right",
    },
  ];

  const steps = [
    ...usersSteps,
    ...(isAdmin || organization.role === "manager" ? managersSteps : []),
  ];

  return <Tutorial steps={steps} section="organization" />;
}

import { Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { useProfile } from "../../providers/UserProfileProvider";
import AdminMenu from "./AdminMenu";
import HelpMenu from "./HelpMenu";
import UserProfile from "./UserProfile";

function NavBar() {
  const { isAdmin, organization } = useProfile();

  return (
    <AppBar
      position="sticky"
      color="default"
      elevation={0}
      sx={{
        borderBottom: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      <Toolbar
        disableGutters={true}
        sx={{ paddingLeft: "12px", paddingRight: "12px" }}
      >
        <Link to="/home" className="ui-home-link">
          <img src="/logo48-horiz-with-text.png" alt="logo" />
        </Link>
        <Box style={{ flex: 1, paddingLeft: "4ch" }}>
          <Typography
            variant="h5"
            color="primary"
            sx={{ textShadow: "1px 1px 2px" }}
          >
            <Link
              className="ui-organization-link"
              to={`/organizations/${organization.id}`}
              style={{ textDecoration: "inherit", color: "inherit" }}
            >
              {organization.name}
            </Link>
          </Typography>
        </Box>
        {isAdmin && <AdminMenu />}
        <Box sx={{ mr: 1 }}>
          <HelpMenu />
        </Box>
        <UserProfile />
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;

import { Login } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import authService from "../../services/authService";

export default function LoginDialog({ open, onClose }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          padding: 15,
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        <div>
          <img
            src="/logo48.png"
            alt="logo"
            width="48"
            height="48"
            style={{ display: "inline-block" }}
          />
        </div>
        {t("Welcome")}
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center", overflow: "visible" }}>
        <Box sx={{ marginTop: "20px" }}>
          <Button
            startIcon={<Login />}
            component={Link}
            to="/home"
            variant="contained"
            sx={{ textTransform: "none", whiteSpace: "nowrap" }}
          >
            {t("Login with {{idp}}", { idp: "Keycloak" })}
          </Button>
          <Divider sx={{ my: "10px" }}></Divider>
          <Typography fontSize={14}>
            <Trans i18nKey={"newUserRegister"}>
              text0
              <MuiLink href={authService.getRegisterUrl()}>text2</MuiLink>
            </Trans>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

import { Done, Error, HourglassEmpty } from "@mui/icons-material";
import { CircularProgress, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export function effectiveStatus(statuses) {
  statuses = Array.isArray(statuses) ? statuses : [statuses];

  if (statuses.some((x) => x?.status === "failed")) return "failed";
  else if (statuses.some((x) => x?.status === "in progress"))
    return "in progress";
  else if (statuses.some((x) => x?.status === "pending")) return "pending";
  else if (
    statuses.length != 0 &&
    statuses.every((x) => x?.status === "success")
  )
    return "success";
  else return null;
}

export function effectiveCause(statuses) {
  statuses = Array.isArray(statuses) ? statuses : [statuses];
  const errors = statuses.filter((x) => x?.status === "failed");
  if (errors.length) {
    const causes = new Set(errors.map((x) => x?.cause));
    if (causes.size == 1)
      return causes.values().next().value || "unspecifiedError";
    else return "multipleErrors";
  } else return null;
}

export default function StatusIcon({ status: statuses, sx, showSuccess }) {
  const { t } = useTranslation();
  const status = effectiveStatus(statuses);
  if (status === "success")
    return showSuccess ? <Done color="success" sx={sx} /> : <></>;
  else if (status === "failed") {
    const cause = effectiveCause(statuses);
    return (
      <Tooltip title={t("jobErrorCodes." + cause)}>
        <Error color="error" sx={sx} />
      </Tooltip>
    );
  } else if (status === "pending")
    return <HourglassEmpty color="action" sx={sx} />;
  else if (status === "in progress")
    return <CircularProgress size={24} color="action" sx={sx} />;
  else return <></>;
}

StatusIcon.propTypes = {
  status: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  sx: PropTypes.object,
  showSuccess: PropTypes.bool,
};

StatusIcon.defaultProps = {
  sx: {},
  showSuccess: true,
};

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGetOrganizationsQuery } from "../../services/organizations";
import OrganizationListHeader from "./OrganizationListHeader";
import OrganizationListToolbar from "./OrganizationListToolbar";
import LoadingError from "../system/LoadingError";
import Loading from "../Loading";

export default function OrganizationList() {
  const { data, error, isLoading } = useGetOrganizationsQuery();
  const { t } = useTranslation();

  if (isLoading) return <Loading />;
  else if (error) return <LoadingError error={error} />;

  const rows = data.map((o) => ({
    id: o.id,
    name: o.name || "(unnamed)",
    nbMembers: o.memberCount,
    nbProjects: o.projectCount,
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <OrganizationListToolbar />
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <OrganizationListHeader />
          <TableBody>
            {rows.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell component="th" id={labelId} scope="row">
                    <Link to={`/organizations/${row.id}`}>{row.name}</Link>
                  </TableCell>
                  <TableCell align="right">{row.nbMembers}</TableCell>
                  <TableCell align="right">{row.nbProjects}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

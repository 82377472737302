import { createContext, useContext, useRef, useState } from "react";
import ConfirmationDialog from "../Components/widgets/ConfirmationDialog";

const confirmationDialogContext = createContext(null);

export const useConfirmationDialog = () =>
  useContext(confirmationDialogContext);

const ConfirmationDialogProvider = (props) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ message: "" });
  const resolver = useRef();

  const showConfirmationDialog = (content) => {
    setData(typeof content === "string" ? { message: content } : content);
    setShow(true);
    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  const handleHide = (result) => () => {
    resolver.current && resolver.current(result);
    setShow(false);
  };

  return (
    <confirmationDialogContext.Provider value={{ showConfirmationDialog }}>
      <ConfirmationDialog
        title={data.title}
        message={data.message}
        show={show}
        onOk={handleHide(true)}
        onCancel={handleHide(false)}
        canDismiss={data.canDismiss}
        buttons={data.buttons}
        promptDoNotShowAgain={data.promptDoNotShowAgain}
        onDoNotShowAgain={data.onDoNotShowAgain}
      />
      {props.children}
    </confirmationDialogContext.Provider>
  );
};

export default ConfirmationDialogProvider;

import _ from "lodash";
import File from "./File";

export default class Arch {
  constructor(obj) {
    // arguments validation and coercion
    if (typeof obj === "string") obj = { jaw: obj };
    else if (!obj) throw new Error("jaw must be provided");
    else if (!obj.jaw) throw new Error("jaw attribute must be provided");
    else if (typeof obj.jaw !== "string")
      throw new Error("jaw must be a string");

    let data = {
      file: new File(),
      start: null,
      end: null,
      segmentation: null,
    };
    Object.defineProperties(this, {
      jaw: {
        get: () => data.jaw,
        enumerable: true,
      },
      file: {
        get: () => data.file,
        set: (x) => (data.file = x),
        enumerable: true,
      },
      start: {
        get: () => data.start,
        set: (x) => (data.start = x),
        enumerable: true,
      },
      end: {
        get: () => data.end,
        set: (x) => (data.end = x),
        enumerable: true,
      },
      segmentation: {
        get: () => data.segmentation,
        set: (x) => {
          data.segmentation = x;
        },
        enumerable: true,
      },
      raw: {
        get: () => ({ ...data, file: data.file.raw }),
      },
    });

    // Handle old/deprecated schema version
    if (obj && "segmentationStatus" in obj && !("segmentation" in obj))
      obj.segmentation = { status: obj.segmentationStatus };

    Object.assign(data, {
      ...obj,
      file: new File({
        ...(obj ? obj.file : {}),
        id: obj.jaw,
      }),
    });
  }

  toJSON() {
    return this.raw;
  }

  isReady() {
    return this.file.isDecimated() && this.start !== null && this.end !== null;
  }

  segmentedFileName() {
    return `segmented_${this.jaw}.vtp`;
  }

  segmentedVisualizationFileName() {
    return `segmented_viz_${this.jaw}.ply`;
  }

  segmentedVisualizationFileDownloadName() {
    if (!this.file.originalFileName) return null;
    const pos = this.file.originalFileName.lastIndexOf(".");
    return this.file.originalFileName.substr(0, pos) + "_segmented.ply";
  }

  teeth() {
    const offset = this.jaw == "upper" ? 1 : 17;
    return Array.from(Array(16).keys()).map((x) => x + offset);
  }

  isBlank() {
    return this.start == null && this.end == null && !this.file.isSet();
  }

  coversAllApplicableTeeth(teeth) {
    if (this.start === null || this.end === null) return false;

    // Case where start/end are reversed
    const [start, end] =
      this.start < this.end ? [this.start, this.end] : [this.end, this.start];

    // consider only the teeth that belong to this arch
    return _.intersection(teeth, this.teeth()).every(
      (tooth) => start <= tooth && end >= tooth
    );
  }
}

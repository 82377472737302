import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import NewProjectButton from "../Components/projects/NewProjectButton";
import { HOME } from "../Components/system/Breadcrumbs";
import HomeTutorial from "../Components/tutorial/HomeTutorial";
import MainLayout from "../layout/MainLayout";
import { useProfile } from "../providers/UserProfileProvider";

function Home() {
  const { t } = useTranslation();
  const profile = useProfile();

  const HomeContent = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        alignItems="center"
        minHeight="120px"
        margin="100px 100px 100px 100px"
      >
        <>
          <NewProjectButton organization={profile.organization.id} />
          <Typography>{t("or")}</Typography>
          <Link to="/projects" className="ui-link-to-existing-projects">
            {t("Go to existing projects")}
          </Link>
        </>
      </Box>
    );
  };

  return (
    <MainLayout title={t("Welcome")} breadcrumbs={[HOME]}>
      <HomeTutorial />
      <HomeContent></HomeContent>
    </MainLayout>
  );
}

export default Home;

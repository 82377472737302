import {
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
} from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function FeedbackLabel({ value, compact }) {
  const { t } = useTranslation();
  const [text, Icon] = (() => {
    switch (value) {
      case -2:
        return ["Very dissatisfied", SentimentVeryDissatisfied];
      case -1:
        return ["Somehow dissatisfied", SentimentDissatisfied];
      case 1:
        return ["Somehow satisfied", SentimentSatisfied];
      case 2:
        return ["Very satisfied", SentimentVerySatisfied];
      default:
        return ["No feedback", null];
    }
  })();

  if (compact)
    return (
      <>
        {Icon != null && (
          <Tooltip title={t(text)}>
            <Icon fontSize="medium" color="action" />
          </Tooltip>
        )}
      </>
    );
  else
    return (
      <>
        <Typography variant="inherit">
          {Icon && (
            <Icon
              fontSize="medium"
              color="action"
              style={{ verticalAlign: "middle" }}
            />
          )}
          &nbsp;{t(text)}
        </Typography>
      </>
    );
}

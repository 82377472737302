import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OrganizationHeader from "../Components/Organizations/OrganizationHeader";
import OrganizationMembers from "../Components/Organizations/OrganizationMembers";
import ProjectList from "../Components/projects/ProjectList";
import {
  HOME,
  ORGANIZATION,
  ORGANIZATIONS,
} from "../Components/system/Breadcrumbs";
import OrganizationTutorial from "../Components/tutorial/OrganizationTutorial";
import Tabs from "../Components/widgets/Tabs";
import MainLayout from "../layout/MainLayout";
import OrganizationApiProvider from "../providers/OrganizationApiProvider";
import { useProfile } from "../providers/UserProfileProvider";
import ResourceUtilization from "../Components/Organizations/ResourceUtilization";
import QuotaUtilization from "../Components/Organizations/QuotaUtilization";

export default function Organization() {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin } = useProfile();
  const { t } = useTranslation();

  const myBreadcrumbs = () => [
    HOME,
    ...(isAdmin ? [ORGANIZATIONS] : []),
    ORGANIZATION,
  ];

  const MyContent = () => {
    const { isAdmin, organization } = useProfile();

    const currentTab = (() => {
      if (isAdmin || organization.role == "manager") {
        if (location.pathname.match("/projects/?$")) return 1;
        else if (location.pathname.match("/quotas/?$")) return 2;
        else if (location.pathname.match("/resources/?$")) return 3;
      }
      return 0;
    })();

    return (
      <OrganizationApiProvider>
        <OrganizationHeader />
        <Box
          sx={{
            my: { xs: 3, md: 3 },
          }}
        >
          <OrganizationTutorial />
          <Tabs
            content={{
              members: {
                label: t("Members"),
                content: <OrganizationMembers />,
              },
              ...((isAdmin || organization.role == "manager") && {
                projects: {
                  label: t("Projects"),
                  content: <ProjectList organization={organizationId} />,
                },
                quotas: {
                  label: t("Quotas"),
                  content: <QuotaUtilization organizationId={organizationId} />,
                },
              }),
              ...(isAdmin && {
                resources: {
                  label: t("Resources"),
                  content: (
                    <ResourceUtilization organizationId={organizationId} />
                  ),
                },
              }),
            }}
            current={currentTab}
            onChange={(newValue) => {
              let url = location.pathname.split("/", 3).join("/");
              if (newValue === 1) url += "/projects";
              else if (newValue === 2) url += "/quotas";
              else if (newValue === 3) url += "/resources";

              navigate(url, { replace: true });
            }}
          />
        </Box>
      </OrganizationApiProvider>
    );
  };

  return (
    <MainLayout title={null} breadcrumbs={myBreadcrumbs()}>
      <MyContent />
    </MainLayout>
  );
}

import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import LoginCallback from "../Components/login/LoginCallback";
import Logout from "../Components/login/Logout";
import SilentRenew from "../Components/login/SilentRenew";
import Administrators from "../pages/Administrators";
import FeedbackSurvey from "../pages/FeedbackSurvey";
import Home from "../pages/Home";
import NotificationsSettings from "../pages/NotificationsSettings";
import Organization from "../pages/Organization";
import Organizations from "../pages/Organizations";
import Project from "../pages/Project";
import ProjectHistory from "../pages/ProjectHistory";
import Projects from "../pages/Projects";
import Root from "../pages/Root";
import SystemSettings from "../pages/SystemSettings";
import ToothLabelling from "../pages/ToothLabelling";
import Users from "../pages/Users";
import UserSettings from "../pages/UserSettings";
import WelcomeNewUser from "../pages/WelcomNewUser";
import { PrivateRoute } from "./privateRoute";
import Error from "../pages/Error";
import MainRoute from "./MainRoute";
import OpenSourceAttribution from "../pages/OpenSourceAttribution";

function Router() {
  const projectSubRoutes = (
    <>
      <Route path=":projectId" element={<Outlet />}>
        <Route index element={<Project />} />
        <Route path="labelling" element={<ToothLabelling />} />
        <Route path="history" element={<ProjectHistory />} />
      </Route>
    </>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/signin-oidc" element={<LoginCallback />} />
        <Route exact path="/silentrenew" element={<SilentRenew />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/error" element={<Error />} />
        <Route exact path="/opensource" element={<OpenSourceAttribution />} />
        <Route path="/">
          <Route exact path="" element={<Root />} />
          <Route element={<PrivateRoute />}>
            <Route path="welcome" element={<WelcomeNewUser />} />
            <Route element={<MainRoute />}>
              <Route path="home" element={<Home />} />
              <Route path="projects" element={<Outlet />}>
                <Route index element={<Projects />} />
                {projectSubRoutes}
              </Route>
              <Route path="usersettings" element={<UserSettings />} />
              <Route path="feedback" element={<FeedbackSurvey />} />
              <Route path="organizations" element={<Outlet />}>
                <Route index element={<Organizations />} />
                <Route path=":organizationId" element={<Outlet />}>
                  <Route index element={<Organization />} />
                  <Route path="quotas" element={<Organization />} />
                  <Route path="resources" element={<Organization />} />
                  <Route path="projects" element={<Outlet />}>
                    <Route index element={<Organization />} />
                    {projectSubRoutes}
                  </Route>
                </Route>
              </Route>
              <Route path="administrators" element={<Administrators />} />
              <Route path="systemsettings" element={<SystemSettings />} />
              <Route path="users" element={<Users />} />
              <Route path="notifications" element={<NotificationsSettings />} />
              <Route path="*" element={<Navigate to="/home" replace />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;

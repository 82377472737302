import { useTranslation } from "react-i18next";
import ProjectSubpage from "../Components/project/ProjectSubpage";
import Segmentation from "../Components/project/Segmentation";
import { TOOTH_LABELLING } from "../Components/system/Breadcrumbs";

export default function ToothLabelling() {
  const { t } = useTranslation();
  return (
    <ProjectSubpage title={t("Tooth Labeling")} crumb={TOOTH_LABELLING}>
      <Segmentation />
    </ProjectSubpage>
  );
}

import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useGetQuotasUtilizationQuery } from "../../services/organizations";
import Loading from "../Loading";
import LoadingError from "../system/LoadingError";
import {
  CartesianGrid,
  Legend,
  BarChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
} from "recharts";
import { useOrganizationApi } from "../../providers/OrganizationApiProvider";
import { useTranslation } from "react-i18next";

export default function QuotaUtilization({ organizationId }) {
  const { t, i18n } = useTranslation();
  const { organization } = useOrganizationApi();
  const { data, error, isLoading } =
    useGetQuotasUtilizationQuery(organizationId);

  const quota = organization.quotas?.crown_generation?.monthly;

  const data2 = useMemo(() => {
    const now = new Date();
    const lastMonths = [...Array(6).keys()].map(
      (n) => new Date(now.getFullYear(), now.getMonth() - 5 + n, 1)
    );

    return lastMonths.map((d) => ({
      month: d.toLocaleDateString(i18n.language, { month: "long" }),
      generations:
        data?.[
          `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, "0")}`
        ]?.count ?? 0,
    }));
  }, [data, quota]);

  if (isLoading) return <Loading />;
  else if (error) return <LoadingError error={error} />;

  return (
    <>
      <Typography variant="h6">{t("Quota Utilization")}</Typography>

      <Box marginY={3}>
        <BarChart width={500} height={300} data={data2}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          {quota && (
            <ReferenceLine
              y={quota}
              label={{
                value: t("Quota"),
                position: "insideBottomRight",
              }}
              stroke="red"
              strokeDasharray="5 5"
            />
          )}
          <Bar
            dataKey="generations"
            name={t("Crown generations")}
            fill="#9c27b0"
            fillOpacity={"50%"}
          />
        </BarChart>
      </Box>
    </>
  );
}

import { useTranslation } from "react-i18next";
import "./Root.css";
import { Grid, Link, Paper, Typography } from "@mui/material";

export default function OpenSourceAttribution() {
  const { t } = useTranslation();

  const Content = () => {
    return (
      <>
        <ul>
          <li>
            The Human dental arches image is by Kaligula - Own work,{" "}
            <Link href="https://creativecommons.org/licenses/by-sa/3.0/">
              CC BY-SA 3.0
            </Link>
            ,{" "}
            <Link href="https://commons.wikimedia.org/w/index.php?curid=23315440">
              https://commons.wikimedia.org/w/index.php?curid=23315440
            </Link>
          </li>
          <li>
            Other{" "}
            <Link href="/oss-attribution/attribution.txt">
              open source software
            </Link>
          </li>
        </ul>
      </>
    );
  };

  return (
    <header className="Root-header">
      <Grid container minWidth="640px">
        <Grid item xs>
          <Paper sx={{ p: 3, mx: 20, my: 10, fontSize: "1rem" }}>
            <Typography variant="h4" align="center" marginBottom={3}>
              {t("Open Source Attribution")}
            </Typography>
            <Content />
          </Paper>
        </Grid>
      </Grid>
    </header>
  );
}

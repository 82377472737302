import { Button, Divider, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const AdminMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        sx={{ mr: 2 }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {t("Admin")}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          component={Link}
          to="/users"
          disabled={location.pathname === "/users"}
          onClick={() => setAnchorEl(null)}
        >
          {t("Manage Users")}
        </MenuItem>
        <MenuItem
          component={Link}
          to="/organizations"
          disabled={location.pathname === "/organizations"}
          onClick={() => setAnchorEl(null)}
        >
          {t("Manage Organizations")}
        </MenuItem>
        <MenuItem
          component={Link}
          to="/administrators"
          disabled={location.pathname === "/administrators"}
          onClick={() => setAnchorEl(null)}
        >
          {t("Manage Administrators")}
        </MenuItem>
        <Divider></Divider>
        <MenuItem
          component={Link}
          to="/systemsettings"
          disabled={location.pathname === "/systemsettings"}
          onClick={() => setAnchorEl(null)}
        >
          {t("System Settings")}
        </MenuItem>
        <MenuItem
          component={Link}
          to="/notifications"
          disabled={location.pathname === "/notifications"}
          onClick={() => setAnchorEl(null)}
        >
          {t("Notifications")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default AdminMenu;

import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./Root.css";
import { useEffect } from "react";

function Error() {
  const { t } = useTranslation();

  // refresh page every minute (will bring back to home is server is back up)
  useEffect(() => {
    const timeout = setTimeout(() => window.location.reload(), 60 * 1000);
    return () => clearTimeout(timeout);
  });

  return (
    <header className="Root-header">
      <Dialog
        open={true}
        PaperProps={{
          style: {
            padding: 15,
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>{t("Sorry")}</DialogTitle>
        <DialogContent sx={{ textAlign: "center", overflow: "visible" }}>
          <Typography>
            {t("The application is currently unavailable.")}
          </Typography>
          <Typography>{t("Please try again later.")}</Typography>
        </DialogContent>
      </Dialog>
    </header>
  );
}

export default Error;

import { createApi } from "@reduxjs/toolkit/query/react";
import config from "../config";
import axiosBaseQuery from "../utils/axiosBaseQuery";

export const systemSettingsApi = createApi({
  reducerPath: "systemSettingsApi",
  baseQuery: axiosBaseQuery({ baseUrl: config.backendUrl }),
  tagTypes: ["systemSettings"],
  endpoints: (builder) => ({
    getSystemSettings: builder.query({
      query: () => ({ url: "/settings" }),
      providesTags: ["systemSettings"],
    }),
    updateSystemSettings: builder.mutation({
      query: (patch) => ({
        url: "/settings",
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["systemSettings"],
    }),
    getWorkerImageVersions: builder.query({
      query: () => ({ url: "/workerversions" }),
    }),
  }),
});

export const {
  useGetSystemSettingsQuery,
  useUpdateSystemSettingsMutation,
  useGetWorkerImageVersionsQuery,
} = systemSettingsApi;

import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBackdrop } from "../../providers/BackdropProvider";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { useCreateOrganizationMutation } from "../../services/organizations";

export default function OrganizationListToolbar(props) {
  const { showAlert } = useSnackbar();
  const navigate = useNavigate();
  const [createOrganization] = useCreateOrganizationMutation();
  const { setShowBackdrop } = useBackdrop();
  const { t } = useTranslation();

  const handleNewOrganization = async () => {
    setShowBackdrop(true);
    try {
      const createdOrganizationId = await createOrganization({
        name: "New organization",
      }).unwrap();
      navigate(`/organizations/${createdOrganizationId}`);
    } catch (err) {
      console.error(err);
      showAlert(t("Failed to create"));
    } finally {
      setShowBackdrop(false);
    }
  };

  const NewOrganizationButton = () => (
    <Button
      startIcon={<Add />}
      variant="contained"
      onClick={handleNewOrganization}
      className="ui-new-organization-button"
    >
      {t("New Organization")}
    </Button>
  );

  return (
    <Toolbar
      variant="dense"
      sx={{
        mt: { sm: 2 },
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Box sx={{ flex: "1 1 100%" }}>
        <NewOrganizationButton />
      </Box>
    </Toolbar>
  );
}

OrganizationListToolbar.propTypes = {};

import { createApi } from "@reduxjs/toolkit/query/react";
import config from "../config";
import axiosBaseQuery from "../utils/axiosBaseQuery";

export const projectsApi = createApi({
  reducerPath: "projectsApi",
  baseQuery: axiosBaseQuery({ baseUrl: config.backendUrl }),
  tagTypes: ["projects"],
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: (searchQuery) => ({
        url: `/projects`,
        params: searchQuery,
      }),
      providesTags: ["projects"],
    }),
    getProject: builder.query({
      query: (id) => ({ url: `/projects/${id}` }),
      // No tag providing here because the SocketIO events manually update the cached project
    }),
    createProject: builder.mutation({
      query: (body) => ({ url: `/projects/`, method: "POST", body: body }),
      invalidatesTags: ["projects"],
    }),
    updateProject: builder.mutation({
      query: ({ id, patch }) => ({
        url: `/projects/${id}`,
        method: "PATCH",
        body: patch,
      }),
      // No 'Project' tag invalidation here because the SocketIO events manually update the cached project
      invalidatesTags: ["projects"],
    }),
    deleteProject: builder.mutation({
      query: (id) => ({ url: `/projects/${id}`, method: "DELETE" }),
      invalidatesTags: ["projects"],
    }),
    getProjectHistory: builder.query({
      query: (id) => ({ url: `/projects/${id}/history` }),
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useGetProjectQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectsQuery,
  useGetProjectHistoryQuery,
} = projectsApi;

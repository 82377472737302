import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useQuotaExceededDialog from "../../hooks/useQuotaExceededDialog";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import { useSettings } from "../../providers/UserProfileProvider";
import { startCrownGeneration } from "../../services/apiService";
import { getToothLabel } from "../../utils/ToothNotation";
import CrownGenerationTutorial from "../tutorial/CrownGenerationTutorial";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../widgets/Accordion";
import StatusIcon from "../widgets/StatusIcon";
import ContextAccordion from "./ContextAccordion";
import useVisibilities from "../../hooks/useVisibilities";
import ProjectFilesViewer from "./ProjectFilesViewer";
import FileDownload from "./FileDownload";
import StatusAndVisibility from "./StatusAndVisibility";

export default function CrownGeneration() {
  const {
    project: {
      id: projectId,
      teethUnderRepair,
      upperArch,
      lowerArch,
      toothRestorations,
      ownerOrganization: organizationId,
    },
  } = useProjectApi();
  const { t } = useTranslation();

  const { isVisible, setVisible } = useVisibilities({
    upperArch: teethUnderRepair.some((x) => x <= 16),
    lowerArch: teethUnderRepair.some((x) => x > 16),
    ...Object.fromEntries(
      Object.keys(toothRestorations).map((t) => [`crown${t}`, true])
    ),
    ...Object.fromEntries(
      Object.keys(toothRestorations).map((t) => [`marginLine${t}`, true])
    ),
  });

  const { notation } = useSettings();

  const showQuotaExceededDialog = useQuotaExceededDialog();

  useEffect(async () => {
    for (const x of Object.values(toothRestorations).filter(
      (x) => x.crownGeneration === null
    )) {
      try {
        await startCrownGeneration(projectId, x.tooth);
      } catch (e) {
        if (e.response.status === 429) {
          showQuotaExceededDialog(organizationId);
        }
      }
    }
  }, []);

  const Item = ({
    restoration,
    visible,
    setVisible,
    file,
    downloadName,
    status,
  }) => {
    const extension = file.split(".").pop();
    return (
      <Box display="flex" paddingX={2} alignItems={"center"}>
        <FileDownload
          projectId={projectId}
          file={file}
          downloadFilename={`${downloadName}${getToothLabel(
            restoration.tooth,
            notation
          )}.${extension}`}
          enabled={status === "success"}
        />

        <StatusAndVisibility
          status={status}
          visible={visible}
          setVisible={setVisible}
        />
      </Box>
    );
  };

  return (
    <Grid container justifyContent={"center"} spacing={3}>
      <CrownGenerationTutorial
        setContextExpanded={() => setVisible("context", true)}
      />
      <Grid item xs>
        <Box>
          <ContextAccordion
            project={{
              upperArch,
              lowerArch,
              teethUnderRepair,
              toothRestorations,
            }}
            isVisible={isVisible}
            setVisible={setVisible}
          />
          {Object.entries(toothRestorations).map(([tooth, restoration]) => (
            <Accordion
              key={tooth}
              expanded={isVisible(`crownAccordion${tooth}`, true)}
              onChange={(_, expanded) =>
                setVisible(`crownAccordion${tooth}`, expanded)
              }
              className="ui-crownGeneration-crowns"
            >
              <AccordionSummary>
                <Typography
                  sx={{
                    marginRight: "auto",
                  }}
                >
                  {t("Crown")}&nbsp;(#
                  {getToothLabel(restoration.tooth, notation)})
                </Typography>
                <Typography sx={{ margin: "1ch" }}></Typography>
                <StatusIcon status={restoration.crownGeneration} />
              </AccordionSummary>
              <AccordionDetails>
                <Item
                  restoration={restoration}
                  file={restoration.crownFileName()}
                  downloadName={"crown"}
                  visible={isVisible(`crown${tooth}`)}
                  setVisible={(v) => setVisible(`crown${tooth}`, v)}
                  status={restoration.crownGeneration?.status}
                />
                <Item
                  restoration={restoration}
                  file={restoration.marginLineFileName()}
                  downloadName="marginline"
                  visible={isVisible(`marginLine${tooth}`)}
                  setVisible={(v) => setVisible(`marginLine${tooth}`, v)}
                  status={restoration.marginLine?.status}
                />
                {restoration.toothTemplate !== null &&
                  restoration.toothTemplate !== restoration.tooth && (
                    <Box marginX={2}>
                      <Typography variant="caption">
                        {t("Note: designed as #{{tooth}}", {
                          tooth: getToothLabel(
                            restoration.toothTemplate,
                            notation
                          ),
                        })}
                      </Typography>
                    </Box>
                  )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Grid>
      <Grid item>
        <Box className="ui-crowns-preview">
          <ProjectFilesViewer isVisible={isVisible} />
        </Box>
      </Grid>
    </Grid>
  );
}

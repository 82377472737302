import Typography from "@mui/material/Typography";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      Copyright © 2023 Intellident AI - All Rights Reserved.
    </Typography>
  );
}

export default Copyright;

import { useEffect, useState } from "react";
import arches from "./307px-Human_dental_arches.svg.png";
import Canvas from "./Canvas";

const archImage = (() => {
  const img = new Image();
  img.src = arches;
  return img;
})();

const getScaledTeeth = (size, jaw) => {
  const upperRightTeeth = {
    1: { x: 10, y: 203, w: 47, h: 39, cx: 32, cy: 221, rx: 26, ry: 21 },
    2: { x: 13, y: 161, w: 48, h: 42, cx: 37, cy: 182, rx: 26, ry: 23 },
    3: { x: 24, y: 119, w: 44, h: 46, cx: 45, cy: 141, rx: 25, ry: 25 },
    4: { x: 38, y: 94, w: 35, h: 29, cx: 56, cy: 108, rx: 19, ry: 15 },
    5: { x: 49, y: 67, w: 37, h: 26, cx: 68, cy: 80, rx: 22, ry: 15 },
    6: { x: 65, y: 38, w: 29, h: 33, cx: 80, cy: 54, rx: 17, ry: 19 },
    7: { x: 87, y: 22, w: 31, h: 29, cx: 104, cy: 36, rx: 17, ry: 15 },
    8: { x: 116, y: 9, w: 37, h: 35, cx: 134, cy: 26, rx: 21, ry: 17 },
  };
  const lowerRightTeeth = {
    32: { x: 16, y: 254, w: 35, h: 37, cx: 33, cy: 275, rx: 21, ry: 21 },
    31: { x: 14, y: 292, w: 42, h: 44, cx: 36, cy: 315, rx: 21, ry: 25 },
    30: { x: 21, y: 336, w: 47, h: 46, cx: 45, cy: 360, rx: 23, ry: 26 },
    29: { x: 43, y: 379, w: 33, h: 31, cx: 59, cy: 395, rx: 19, ry: 15 },
    28: { x: 62, y: 406, w: 29, h: 29, cx: 76, cy: 420, rx: 18, ry: 16 },
    27: { x: 82, y: 428, w: 29, h: 29, cx: 95, cy: 442, rx: 14, ry: 16 },
    26: { x: 107, y: 438, w: 22, h: 28, cx: 118, cy: 453, rx: 11, ry: 16 },
    25: { x: 130, y: 445, w: 23, h: 25, cx: 140, cy: 459, rx: 12, ry: 14 },
  };

  const rightTeeth =
    jaw === "upper"
      ? upperRightTeeth
      : jaw === "lower"
      ? lowerRightTeeth
      : { ...upperRightTeeth, ...lowerRightTeeth };

  const reflect = ([num, tooth]) => [
    (num > 8 ? 49 : 17) - parseInt(num),
    { ...tooth, x: 153 * 2 - tooth.x - tooth.w, cx: 153 * 2 - tooth.cx },
  ];

  const teeth = {
    ...rightTeeth,
    ...Object.fromEntries(Object.entries(rightTeeth).map(reflect)),
  };

  // clip for lower jaw
  const offset = jaw === "upper" ? 0 : jaw === "lower" ? 247 : 0;
  Object.values(teeth).forEach((tooth) => {
    tooth.y -= offset;
    tooth.cy -= offset;
  });

  // Scale to current size
  Object.values(teeth).forEach((tooth) => {
    for (var measure in tooth) {
      tooth[measure] = (tooth[measure] * size) / 480;
    }
  });

  return teeth;
};

export default function ToothPicker({
  highlightedTeeth,
  onClick,
  onHover,
  size,
  jaw,
}) {
  const [teeth, setTeeth] = useState({});

  useEffect(() => {
    setTeeth(getScaledTeeth(size, jaw));
  }, [size, jaw]);

  const offset = jaw === "upper" ? 0 : jaw === "lower" ? 247 : 0;
  const clipSize = jaw === "upper" || jaw === "lower" ? 247 : 480;
  const canvasWidth = (307 * size) / 480;
  const canvasHeight = (clipSize * size) / 480;

  const highligthTooth = (ctx, tooth, color) => {
    var gradient = ctx.createRadialGradient(
      tooth.cx,
      tooth.cy,
      0,
      tooth.cx,
      tooth.cy,
      Math.max(tooth.rx, tooth.ry)
    );
    gradient.addColorStop(0, color + "f0");
    gradient.addColorStop(0.9, color + "90");
    gradient.addColorStop(1, "#FFFFFF00");

    ctx.beginPath();
    ctx.stroke();
    ctx.beginPath();
    ctx.fillStyle = gradient;
    ctx.ellipse(tooth.cx, tooth.cy, tooth.rx, tooth.ry, 0, 0, 2 * Math.PI);
    ctx.fill();
  };

  const draw = (ctx) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.drawImage(
      archImage,
      0,
      offset,
      307,
      clipSize,
      0,
      0,
      canvasWidth,
      (clipSize * size) / 480
    );
    highlightedTeeth.forEach((t) => {
      const { tooth, color } =
        typeof t === "object" ? t : { tooth: t, color: "#3370d4" };
      if (teeth[tooth]) highligthTooth(ctx, teeth[tooth], color);
    });
  };

  const identifyTooth = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    for (var i in teeth) {
      const t = teeth[i];
      if (x >= t.x && y > t.y && x <= t.x + t.w && y <= t.y + t.h) {
        return parseInt(i);
      }
    }
    return null;
  };

  const onMouseMove = function (e) {
    onHover(identifyTooth(e));
  };

  const onMouseClick = function (e) {
    const tooth = identifyTooth(e);
    if (tooth !== null) onClick(tooth);
  };

  return (
    <Canvas
      draw={draw}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseClick}
      onMouseLeave={() => onHover(null)}
      width={canvasWidth}
      height={canvasHeight}
    />
  );
}

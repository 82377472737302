import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LoginDialog from "../Components/login/LoginDialog";
import "./Root.css";

function Root() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <header className="Root-header">
      <Button onClick={() => setOpen(true)} variant="contained">
        {t("Login")}
      </Button>
      <LoginDialog open={open} onClose={() => setOpen(false)} />
    </header>
  );
}

export default Root;

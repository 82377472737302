import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";

export default function OrganizationListHeader(props) {
  const { t } = useTranslation();

  const headCells = [
    {
      id: "name",
      label: t("Name"),
    },
    {
      id: "nbMembers",
      label: t("Members"),
    },
    {
      id: "nbProjects",
      label: t("Projects"),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={index === 0 ? "left" : "right"}
            className={"ui-header-" + headCell.id}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

OrganizationListHeader.propTypes = {};

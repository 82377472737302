import axios from "axios";
import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import config from "../config";

class AuthService {
  userManager;

  constructor() {
    this.userManager = new Promise(async (resolve, reject) => {
      try {
        await this.bootstrap();
        await this.loadOpenIdConnectInfo();
        resolve(this.initUserManager());
        if (window.location.pathname === "/error") window.location = "/home";
      } catch (e) {
        if (window.location.pathname !== "/error") window.location = "/error";
        reject(e);
      }
    });
  }

  async bootstrap() {
    // retrieve the OpenID Connect info from backend
    const bootstrapResponse = await axios.get(`${config.backendUrl}/bootstrap`);
    if (bootstrapResponse.status != 200) {
      // TODO: translate
      reject("failed to load boostrap information from backend");
      return;
    }
    const { authUrl, clientId } = bootstrapResponse.data;

    this.oidcSettings = {
      authority: authUrl,
      client_id: clientId,
      redirect_uri: config.appUrl + "/signin-oidc",
      silent_redirect_uri: config.appUrl + "/silentrenew",
      post_logout_redirect_uri: config.appUrl,
      audience: undefined,
      response_type: "code",
      automaticSilentRenew: true,
      loadUserInfo: true,
      scope: undefined,
    };
  }

  async loadOpenIdConnectInfo() {
    const response = await axios.get(
      this.oidcSettings.authority + "/.well-known/openid-configuration"
    );
    if (response.status !== 200) {
      // TODO: translate
      reject("failed to load OpenID Connect configuration");
    }
    this.oidMetadata = response.data;
  }

  initUserManager() {
    const userManager = new UserManager({
      ...this.oidcSettings,
      userStore: new WebStorageStateStore({
        store: window.sessionStorage,
      }),
      metadata: this.oidMetadata,
    });

    userManager.events.addUserLoaded((user) => {
      if (window.location.href.startsWith(this.oidcSettings.redirect_uri)) {
        window.location.replace(localStorage.getItem("redirectUri"));
      }
    });
    userManager.events.addSilentRenewError((e) => {});
    userManager.events.addAccessTokenExpired(() => {
      this.logout();
    });

    return userManager;
  }

  signinRedirectCallback = async () => {
    return (await this.userManager).signinRedirectCallback();
  };

  getUser = async () => {
    const mgr = await this.userManager;
    const user = await mgr.getUser();
    if (!user) {
      return await mgr.signinRedirectCallback();
    }
    return user;
  };

  signinRedirect = async () => {
    localStorage.setItem("redirectUri", window.location.href);
    (await this.userManager).signinRedirect({});
  };

  isAuthenticated = () => {
    const key = Object.keys(sessionStorage).filter((key) =>
      key.startsWith("oidc.user:")
    )[0];
    if (!key) return false;

    const oidcStorage = JSON.parse(sessionStorage.getItem(key));
    return !!oidcStorage && !!oidcStorage.access_token;
  };

  signinSilentCallback = async () => {
    (await this.userManager).signinSilentCallback();
  };

  logout = async () => {
    const mgr = await this.userManager;
    mgr.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token"),
    });
    mgr.clearStaleState();
  };

  getAccountUrl() {
    return this.oidcSettings ? this.oidcSettings.authority + "/account" : "";
  }

  getRegisterUrl() {
    return this.oidcSettings
      ? `${this.oidcSettings.authority}/protocol/openid-connect/registrations?client_id=${this.oidcSettings.client_id}&response_type=code&scope=openid&redirect_uri=${config.appUrl}`
      : "";
  }
}

export default new AuthService();

/*export default {
  isAuthenticated: () => true,
  getUser: () => new Promise((r) => r({ profile: { email: "bob@acme.com" } })),
};*/

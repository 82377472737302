import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiStep from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ProjectState from "../../model/ProjectState";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import {
  useSettings,
  useUpdateSettings,
} from "../../providers/UserProfileProvider";
import ArchScans from "./ArchScans";
import CompletedProject from "./CompletedProject";
import CrownGeneration from "./CrownGeneration";
import ProjectInfo from "./ProjectInfo";
import ProjectMenu from "./ProjectMenu";
import { StepButton } from "@mui/material";

class Step {
  static PROJECT_INFO = new Step(0, "Project Info");
  static SCANS = new Step(1, "Scans");
  static CROWN_GENERATION = new Step(2, "Crown Generation");
  static CONCLUSION = new Step(3, "Download");
  static {
    this.PROJECT_INFO.next = this.SCANS;

    this.SCANS.next = this.CROWN_GENERATION;
    this.SCANS.previous = this.PROJECT_INFO;

    this.CROWN_GENERATION.next = this.CONCLUSION;
    this.CROWN_GENERATION.previous = this.SCANS;

    this.CONCLUSION.previous = this.CROWN_GENERATION;
  }

  constructor(value, name) {
    this.value = value;
    this.name = name;
  }

  nextButtonDisabled(project) {
    return (
      (this == Step.PROJECT_INFO &&
        (project.teethUnderRepair.length == 0 || !project.refNumber)) ||
      (this == Step.SCANS && !project.isReadyForCrownGeneration()) ||
      (this == Step.CROWN_GENERATION &&
        Object.values(project.toothRestorations).some(
          (x) => x.crownGeneration?.status !== "success"
        )) ||
      !project.dataUsageConsent
    );
  }
}

const getPageForState = (state) =>
  state === ProjectState.NEW
    ? Step.PROJECT_INFO
    : state === ProjectState.SCANS
    ? Step.SCANS
    : state === ProjectState.CROWN_GENERATION
    ? Step.CROWN_GENERATION
    : state === ProjectState.COMPLETE
    ? Step.CONCLUSION
    : () => {
        throw Error("unexpected/invalid project state");
      };

export default function ProjectWorflow() {
  const navigate = useNavigate();
  const { project, saveProject } = useProjectApi();
  const { showConfirmationDialog } = useConfirmationDialog();
  const { t } = useTranslation();
  const { disableFeedbackPrompt } = useSettings();
  const updateSettings = useUpdateSettings();

  const projectStep = getPageForState(project.state);
  const [activeStep, setActiveStep] = useState(projectStep);

  const [errorOnPage, setErrorOnPage] = useState(false);

  function nextButtonCaption(step) {
    return step === Step.CONCLUSION ? t("Done") : t("Next");
  }

  function getStepContent(step) {
    switch (step) {
      case Step.PROJECT_INFO:
        return <ProjectInfo setErrorOnPage={setErrorOnPage} />;
      case Step.SCANS:
        return <ArchScans />;
      case Step.CROWN_GENERATION:
        return <CrownGeneration />;
      case Step.CONCLUSION:
        return <CompletedProject />;
      default:
        throw new Error("Unknown step");
    }
  }

  const transitionProject = (newState) =>
    saveProject({
      state: newState,
    });

  const confirmNoAntagonist = () =>
    showConfirmationDialog({
      title: t("projects.actions.generateWithoutAntagonist.title"),
      message: (
        <>
          <p>{t("projects.actions.generateWithoutAntagonist.paragraph1")}</p>
          <p>{t("projects.actions.generateWithoutAntagonist.paragraph2")}</p>
        </>
      ),
      canDismiss: false,
    });

  const confirmNoFeedback = () =>
    showConfirmationDialog({
      title: t("projects.feedbackRequest"),
      message: (
        <>
          <p>
            {t("projects.actions.completeProjectWithoutFeedback.paragraph1")}
          </p>
          <p>
            {t("projects.actions.completeProjectWithoutFeedback.paragraph2")}
          </p>
        </>
      ),
      canDismiss: false,
      buttons: [t("Proceed"), t("Cancel")],
      promptDoNotShowAgain: true,
      onDoNotShowAgain: (newValue) => {
        updateSettings({ disableFeedbackPrompt: newValue });
      },
    });

  const handleNext = async () => {
    if (activeStep === Step.PROJECT_INFO && project.state == ProjectState.NEW) {
      transitionProject(ProjectState.SCANS);
    } else if (
      activeStep === Step.SCANS &&
      project.state == ProjectState.SCANS
    ) {
      if (
        (!project.upperArch.isBlank() && !project.lowerArch.isBlank()) ||
        (await confirmNoAntagonist())
      ) {
        transitionProject(ProjectState.CROWN_GENERATION);
      } else {
        return;
      }
    } else if (
      activeStep === Step.CROWN_GENERATION &&
      project.state == ProjectState.CROWN_GENERATION
    ) {
      transitionProject(ProjectState.COMPLETE);
    } else if (activeStep === Step.CONCLUSION) {
      if (
        !!project.feedback ||
        disableFeedbackPrompt ||
        (await confirmNoFeedback())
      ) {
        navigate("/projects");
      }

      return;
    }
    setActiveStep(activeStep.next);
  };

  const handleBack = () => {
    setActiveStep(activeStep.previous);
  };

  return (
    <>
      <Box display="flex">
        <Typography component="h1" variant="h4" align="center" width="100%">
          {t("Dental Design Project")}
        </Typography>
        <ProjectMenu />
      </Box>
      <Stepper nonLinear activeStep={activeStep.value} sx={{ pt: 3, pb: 5 }}>
        {Object.values(Step).map((step) => (
          <MuiStep completed={step.value < projectStep.value} key={step.value}>
            <StepButton
              disabled={step.value > projectStep.value}
              onClick={() => setActiveStep(step)}
            >
              {t(step.name)}
            </StepButton>
          </MuiStep>
        ))}
      </Stepper>
      {getStepContent(activeStep)}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {activeStep !== Step.PROJECT_INFO && (
          <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
            {t("Back")}
          </Button>
        )}

        <Button
          variant="contained"
          onClick={handleNext}
          disabled={errorOnPage || activeStep.nextButtonDisabled(project)}
          sx={{ mt: 3, ml: 1 }}
          className="ui-workflow-next-button"
        >
          {nextButtonCaption(activeStep)}
        </Button>
      </Box>
    </>
  );
}

import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../widgets/Accordion";
import VisibilityOn from "@mui/icons-material/Visibility";
import { VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { getToothLabel } from "../../utils/ToothNotation";
import { useSettings } from "../../providers/UserProfileProvider";

export default function ContextAccordion({
  project,
  isVisible,
  setVisible,
  expanded = true,
}) {
  const { notation } = useSettings();
  const { t } = useTranslation();
  const { upperArch, lowerArch, teethUnderRepair, toothRestorations } = project;

  const Item = ({ label, visible, setVisible, file }) => (
    <ListItem>
      <ListItemText
        primary={label}
        secondary={file.originalFileName}
        sx={{ maxWidth: "350px", overflow: "hidden" }}
      />
      <ListItemIcon sx={{ minWidth: 0 }}>
        <IconButton onClick={() => setVisible(!visible)}>
          {visible ? <VisibilityOn /> : <VisibilityOff />}
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );

  return (
    <Accordion
      expanded={isVisible("context", expanded)}
      onChange={(_, expanded) => setVisible("context", expanded)}
      className="ui-crownGeneration-context"
    >
      <AccordionSummary>
        <Typography>{t("Context")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {upperArch.file.isSet() && (
            <Item
              visible={isVisible("upperArch")}
              setVisible={(v) => setVisible("upperArch", v)}
              file={upperArch.file}
              label={t("Upper arch")}
            />
          )}
          {lowerArch.file.isSet() && (
            <Item
              visible={isVisible("lowerArch")}
              setVisible={(v) => setVisible("lowerArch", v)}
              file={lowerArch.file}
              label={t("Lower arch")}
            />
          )}
          {teethUnderRepair.map(
            (tooth) =>
              toothRestorations[tooth].dieFile.isSet() && (
                <Item
                  key={tooth}
                  visible={isVisible(`die${tooth}`)}
                  setVisible={(v) => setVisible(`die${tooth}`, v)}
                  file={toothRestorations[tooth].dieFile}
                  label={t("#{{tooth}} die", {
                    tooth: `${getToothLabel(tooth, notation)}`,
                  })}
                />
              )
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBackdrop } from "../../providers/BackdropProvider";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { useProfile } from "../../providers/UserProfileProvider";
import { useCreateProjectMutation } from "../../services/projects";

export default function NewProjectButton({ organization }) {
  const { showAlert } = useSnackbar();
  const { setShowBackdrop } = useBackdrop();
  const [createProject] = useCreateProjectMutation();
  const navigate = useNavigate();
  const user = useProfile().user.sub;
  const { t } = useTranslation();

  const projectBody = {
    ownerUser: user,
    ownerOrganization: organization,
  };

  const onClick = async () => {
    setShowBackdrop(true);
    try {
      const createdProjectId = await createProject(projectBody).unwrap();
      navigate(`/projects/${createdProjectId}`);
    } catch (err) {
      console.error(err);
      showAlert(t("Failed to create"));
    } finally {
      setShowBackdrop(false);
    }
  };
  return (
    <>
      <Button
        startIcon={<Add />}
        variant="contained"
        onClick={onClick}
        className="ui-new-project-button"
      >
        {t("New project")}
      </Button>
    </>
  );
}

import { TableCell, TableSortLabel } from "@mui/material";
import { useState } from "react";
import ColumnMenu from "./ColumnMenu";

export default function TableHeaderCell({
  headCell,
  first,
  orderBy,
  order,
  onClick,
  menu,
  filters,
}) {
  const [hovered, setHovered] = useState(false);
  return (
    <TableCell
      align={first ? "left" : "right"}
      padding={first ? "none" : "normal"}
      sortDirection={orderBy === headCell.id ? order : false}
      className={"ui-tableHeader-" + headCell.id}
    >
      <div
        style={{
          display: "inline-flex",
          justifyContent: "flex-start",
          flexDirection: "inherit",
          alignItems: "center",
          flexWrap: "nowrap",
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
          onClick={onClick}
        >
          {headCell.label}
        </TableSortLabel>
        {menu && (
          <ColumnMenu
            name={headCell.id}
            hovered={hovered}
            filters={filters}
            onClose={() => setHovered(false)}
          >
            {menu}
          </ColumnMenu>
        )}
      </div>
    </TableCell>
  );
}

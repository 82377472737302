import { MoreVert } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProjectState from "../../model/ProjectState";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import { useSettings } from "../../providers/UserProfileProvider";
import { getToothLabel } from "../../utils/ToothNotation";
import SingleToothPickerDialog from "../widgets/SingleToothPickerDialog";
import FileUpload from "./FileUpload";

export default function DieInputs({
  toothRestoration,
  checkAndConfirmProjectRollback,
}) {
  const { tooth, dieFile, toothTemplate } = toothRestoration;
  const { notation } = useSettings();
  const { saveProject } = useProjectApi();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [designAsDialogOpen, setDesignAsDialogOpen] = useState(false);
  const showDesignAsDialog = async () => {
    setAnchorEl(null);
    if (await checkAndConfirmProjectRollback()) setDesignAsDialogOpen(true);
  };
  const setToothTemplate = async (newTooth) => {
    await saveProject({
      [`toothRestorations.${tooth}.toothTemplate`]: newTooth,
      state: ProjectState.SCANS,
    });
    setDesignAsDialogOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ width: "10%" }}>
          #{getToothLabel(tooth, notation)}:
        </Typography>
        <FileUpload
          model={dieFile}
          checkAndConfirmProjectRollback={checkAndConfirmProjectRollback}
          maxWidth={"350px"}
        />
        <IconButton
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className="ui-die-input-menu"
        >
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={showDesignAsDialog}>{t("Design as...")}</MenuItem>
        </Menu>
        {designAsDialogOpen && (
          <SingleToothPickerDialog
            title={t("Tooth Design Template")}
            onClose={() => setDesignAsDialogOpen(false)}
            tooth={toothTemplate || tooth}
            onChange={setToothTemplate}
          />
        )}
      </Box>
      {toothTemplate !== null && toothTemplate !== tooth && (
        <Box sx={{ ml: "10%", mt: "-.5rem", mb: ".25rem" }}>
          <Typography variant="caption">
            Design as {getToothLabel(toothTemplate, notation)}
          </Typography>
        </Box>
      )}
    </>
  );
}

import { Archive, DownloadForOffline } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useBackdrop } from "../../providers/BackdropProvider";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { exportProject } from "../../services/apiService";
import NewProjectButton from "./NewProjectButton";
import { useProfile } from "../../providers/UserProfileProvider";

export default function ProjectListToolbar(props) {
  const {
    organization,
    selectedProjectIds,
    onDeleteProject,
    onArchiveProjects,
    filters,
    setFilters,
  } = props;
  const { showAlert } = useSnackbar();
  const { setShowBackdrop } = useBackdrop();
  const { isAdmin } = useProfile();
  const { t } = useTranslation();

  const DeleteButton = () => {
    const { showConfirmationDialog } = useConfirmationDialog();

    const handleDeleteProjects = async () => {
      const mesg = t("Delete {{nb}} projects?", {
        nb: selectedProjectIds.length,
      });
      (await showConfirmationDialog({
        title: mesg,
        message: t("projects.deleteConfirmation"),
      })) && onDeleteProject();
    };

    return (
      <Tooltip title={t("Delete selected projects")}>
        <span>
          <IconButton
            color="primary"
            disabled={selectedProjectIds.length == 0}
            onClick={handleDeleteProjects}
            className="ui-deleteProjects-button"
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  const ArchiveButton = () => {
    const { showConfirmationDialog } = useConfirmationDialog();

    const handleArchiveProjects = async () => {
      const mesg = t(
        "Archive {{nb}} projects? Archived projects are no longer visible and can only be recovered by administrators.",
        {
          nb: selectedProjectIds.length,
        }
      );
      (await showConfirmationDialog(mesg)) && onArchiveProjects();
    };

    return (
      <Tooltip title={t("Archive selected projects")}>
        <span>
          <IconButton
            color="primary"
            disabled={selectedProjectIds.length == 0}
            onClick={handleArchiveProjects}
            className="ui-archiveProjects-button"
          >
            <Archive />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  const handleExportProject = async () => {
    setShowBackdrop(true);
    try {
      await exportProject(selectedProjectIds[0]);
    } catch (e) {
      console.error(e);
      showAlert(t("Failed to export"));
    } finally {
      setShowBackdrop(false);
    }
  };

  const ExportProjectButton = () => (
    <Tooltip title={t("Export the selected project")}>
      <span>
        <IconButton
          color="primary"
          disabled={selectedProjectIds.length != 1}
          onClick={handleExportProject}
          className="ui-exportProject-button"
        >
          <DownloadForOffline />
        </IconButton>
      </span>
    </Tooltip>
  );

  return (
    <Toolbar
      variant="dense"
      sx={{
        mt: { sm: 2 },
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Box sx={{ flex: "1 1 100%" }}>
        <Stack direction="row" spacing={2}>
          {organization && <NewProjectButton organization={organization} />}
          <Button
            size="small"
            disabled={!filters.hasCustomizations()}
            onClick={() => setFilters(filters.clear())}
            className="ui-button-clear-all-filter"
          >
            {t("Clear all filters")}
          </Button>
        </Stack>
      </Box>
      <ExportProjectButton />
      <ArchiveButton />
      {isAdmin && <DeleteButton />}
    </Toolbar>
  );
}

ProjectListToolbar.propTypes = {
  selectedProjectIds: PropTypes.array.isRequired,
  onDeleteProject: PropTypes.func.isRequired,
  onArchiveProjects: PropTypes.func.isRequired,
};

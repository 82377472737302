import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import {
  useGetUserProfileQuery,
  useUpdateUserSettingsMutation,
} from "../services/userprofile";
import { useAuth } from "./authProvider";
import { useSnackbar } from "./SnackbarProvider";
import Loading from "../Components/Loading";
import LoadingError from "../Components/system/LoadingError";

const settingsContext = createContext(null);

export const useProfile = () => useContext(settingsContext);
export const useSettings = () => useContext(settingsContext).settings;
export const useUpdateSettings = () =>
  useContext(settingsContext).updateSettings;

const UserProfileProvider = (props) => {
  const { data, error, isLoading } = useGetUserProfileQuery();
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const { showAlert } = useSnackbar();
  const { t, i18n } = useTranslation();

  // cache authentication data for convenience
  const [user, setUser] = useState(null);
  const { getUser } = useAuth();
  useEffect(() => getUser().then(({ profile }) => setUser(profile)), [getUser]);

  useEffect(() => {
    const lang = data?.settings?.lang;
    if (lang && lang != i18n.language) i18n.changeLanguage(lang);
  });

  if (!user || isLoading) return <Loading />;
  if (error) {
    if (error.status === 404) {
      return (
        <>
          Redirecting...
          <Navigate to={"/welcome"} />
        </>
      );
    } else {
      return <LoadingError error={error} />;
    }
  }

  const updateSettings = (patch) => {
    updateUserSettings(patch)
      .unwrap()
      .catch((e) => showAlert(t("Failed to save settings")));
  };

  const normalizedSettings = (({
    dentalNotation,
    disableTutorial,
    ...otherSettings
  }) => ({
    notation: dentalNotation || "FDI",
    disableTutorial: disableTutorial || false,
    ...otherSettings,
  }))(data.settings);

  const profile = {
    ...data,
    user,
    settings: normalizedSettings,
    updateSettings,
  };

  return (
    <settingsContext.Provider value={profile}>
      {props.children}
    </settingsContext.Provider>
  );
};

export default UserProfileProvider;

import { useTranslation } from "react-i18next";
import Tutorial from "../widgets/Tutorial";

export default function AdministratorsTutorial() {
  const { t } = useTranslation(["tutorial"]);

  const steps = [
    {
      target: "body",
      title: t("administrators.general.title"),
      content: (
        <>
          <p>{t("administrators.general.paragraph1")}</p>
          <p>{t("administrators.general.paragraph2")}</p>
        </>
      ),
      disableBeacon: true,
      placement: "center",
    },
    {
      target: ".ui-user-picker",
      content: (
        <>
          <p>{t("administrators.picker.paragraph1")}</p>
        </>
      ),
      placement: "right",
    },
    {
      target: ".ui-remove-administrator-button",
      content: (
        <>
          <p>{t("administrators.remove.paragraph1")}</p>
        </>
      ),
      placement: "left",
    },
  ];

  return <Tutorial steps={steps} section="administrators"></Tutorial>;
}

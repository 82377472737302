import { createApi } from "@reduxjs/toolkit/query/react";
import config from "../config";
import axiosBaseQuery from "../utils/axiosBaseQuery";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: axiosBaseQuery({ baseUrl: config.backendUrl }),
  tagTypes: ["user"],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({ url: `/users` }),
      providesTags: ["user"],
    }),
    updateUser: builder.mutation({
      query: ({ id, patch }) => ({
        url: `/users/${id}`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["user"],
    }),
    getUser: builder.query({
      query: (id) => ({ url: `/users/${id}` }),
      providesTags: (result, error, arg) => [{ type: "user", id: arg }],
    }),
  }),
});

export const { useGetUserQuery, useGetUsersQuery, useUpdateUserMutation } =
  usersApi;

import { Box, Tab } from "@mui/material";
import MuiTabs from "@mui/material/Tabs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function Tabs({ content, current, onChange }) {
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <MuiTabs
          value={current}
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
        >
          {Object.entries(content).map(([t, { label }]) => (
            <Tab key={t} label={label} className={"ui-tab-" + t} />
          ))}
        </MuiTabs>
      </Box>
      {Object.values(content).map(({ content }, index) => (
        <TabPanel value={current} index={index} key={index}>
          {content}
        </TabPanel>
      ))}
    </>
  );
}

import { Help } from "@mui/icons-material";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateSettings } from "../../providers/UserProfileProvider";
import { useAppState } from "../../reducers/app";
import AboutDialog from "./AboutDialog";
import { Link } from "react-router-dom";

export default function HelpMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAboutDialog, setShowAboutDialog] = useState(false);
  const { t } = useTranslation();
  const { restartTutorial } = useAppState();
  const updateSettings = useUpdateSettings();

  function handleShowTutorial() {
    restartTutorial();
    updateSettings({ disableTutorial: false });
  }

  return (
    <>
      <AboutDialog
        open={showAboutDialog}
        onClose={() => setShowAboutDialog(false)}
      />
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className="ui-help-menu"
      >
        <Help fontSize="large" color="primary"></Help>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            handleShowTutorial();
            setAnchorEl(null);
          }}
        >
          {t("Start/resume tutorial")}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
          }}
          component={Link}
          to="/opensource"
          target="_blank"
        >
          {t("Open Source Attribution")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowAboutDialog(true);
            setAnchorEl(null);
          }}
        >
          {t("About...")}
        </MenuItem>
      </Menu>
    </>
  );
}

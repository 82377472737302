import { useTranslation } from "react-i18next";
import ProjectList from "../Components/projects/ProjectList";
import { HOME, PROJECTS } from "../Components/system/Breadcrumbs";
import ProjectsTutorial from "../Components/tutorial/ProjectsTutorial";
import MainLayout from "../layout/MainLayout";
import { useProfile } from "../providers/UserProfileProvider";

function ProjectListWrapper() {
  const { isAdmin, organization, user } = useProfile();

  return (
    <>
      {isAdmin ? (
        <ProjectList />
      ) : organization ? (
        organization.role === "manager" ? (
          <ProjectList organization={organization.id} />
        ) : (
          <ProjectList user={user.sub} organization={organization.id} />
        )
      ) : (
        <ProjectList user={user.sub} />
      )}
    </>
  );
}

function Projects() {
  const { t } = useTranslation();
  return (
    <MainLayout
      title={t("Dental Design Projects")}
      breadcrumbs={[HOME, PROJECTS]}
    >
      <ProjectsTutorial />
      <ProjectListWrapper />
    </MainLayout>
  );
}

export default Projects;

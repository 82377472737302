import { Alert, Snackbar } from "@mui/material";
import { createContext, useContext, useEffect, useRef, useState } from "react";

const snackbarContext = createContext(null);

export const useSnackbar = () => useContext(snackbarContext);

const SnackbarProvider = (props) => {
  const [state, setState] = useState({ open: false });
  const hideTimer = useRef(null);

  const stateRef = useRef();
  stateRef.current = state;

  const handleClose = (event, reason) => {
    if (reason !== "clickaway") {
      setState({ ...stateRef.current, open: false });
      hideTimer.current = null;
    }
  };

  const showAlert = (message, severity = "error") => {
    setState({ message, severity, open: true });
    if (hideTimer.current) clearTimeout(hideTimer.current);
    hideTimer.current = setTimeout(() => handleClose(), 5000);
  };

  const showSuccessAlert = (message) => showAlert(message, "success");

  useEffect(
    () => () => hideTimer.current && clearTimeout(hideTimer.current),
    []
  );

  return (
    <snackbarContext.Provider value={{ showAlert, showSuccessAlert }}>
      <Snackbar open={state.open} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={state.severity}
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      {props.children}
    </snackbarContext.Provider>
  );
};

export default SnackbarProvider;

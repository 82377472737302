import { useTranslation } from "react-i18next";
import { useProfile } from "../../providers/UserProfileProvider";
import Tutorial from "../widgets/Tutorial";

export default function ProjectsTutorial() {
  const { isAdmin } = useProfile();
  const { t } = useTranslation(["tutorial"]);

  const steps = [
    {
      target: "body",
      title: t("projects.general.title"),
      content: (
        <>
          <p>{t("projects.general.paragraph1")}</p>
          <p>{t("projects.general.paragraph2")}</p>
        </>
      ),
      disableBeacon: true,
      placement: "center",
    },
    {
      target: ".ui-tableHeader-refNumber",
      content: (
        <>
          <p>{t("projects.refNumber.paragraph1")}</p>
        </>
      ),
      placement: "bottom",
    },
    ...(isAdmin
      ? [
          {
            target: ".ui-tableHeader-owner",
            content: (
              <>
                <p>{t("projects.owner.paragraph1")}</p>
              </>
            ),
            placement: "bottom",
          },
        ]
      : []),
    {
      target: ".ui-tableHeader-teethUnderRepair",
      content: (
        <>
          <p>{t("projects.teeth.paragraph1")}</p>
        </>
      ),
      placement: "bottom",
    },
    {
      target: ".ui-tableHeader-state",
      content: (
        <>
          <p>{t("projects.state.paragraph1")}</p>
        </>
      ),
      placement: "bottom",
    },
    {
      target: ".ui-tableHeader-createdAt",
      content: (
        <>
          <p>
            {t(
              isAdmin
                ? "projects.createdAt.paragraph1-admin"
                : "projects.createdAt.paragraph1"
            )}
          </p>
          <p>{t("projects.createdAt.paragraph2")}</p>
        </>
      ),
      placement: "bottom",
    },
    {
      target: ".ui-tableHeader-updatedAt",
      content: (
        <>
          <p>{t("projects.updatedAt.paragraph1")}</p>
          <p>{t("projects.createdAt.paragraph2")}</p>
        </>
      ),
      placement: "bottom",
    },
    {
      target: ".ui-tableHeader",
      content: (
        <>
          <p>{t("projects.tableHeader.paragraph1")}</p>
        </>
      ),
      placement: "bottom",
    },
    {
      target: ".ui-filter-button-refNumber",
      content: (
        <>
          <p>{t("projects.filters.refNumber")}</p>
          <p>{t("projects.filters.refNumber2")}</p>
        </>
      ),
      placement: "bottom",
      before: ({ target }) => {
        document.querySelector(target).style.opacity = 0.5;
      },
      after: ({ target }) => {
        document.querySelector(target).style.opacity = 0;
      },
    },
    {
      target: ".ui-filter-button-teethUnderRepair ",
      content: (
        <>
          <p>{t("projects.filters.teeth")}</p>
        </>
      ),
      placement: "bottom",
      before: ({ target }) => {
        document.querySelector(target).style.opacity = 0.5;
      },
      after: ({ target }) => {
        document.querySelector(target).style.opacity = 0;
      },
    },
    {
      target: ".ui-filter-button-state",
      content: (
        <>
          <p>{t("projects.filters.state")}</p>
        </>
      ),
      placement: "bottom",
      before: ({ target }) => {
        document.querySelector(target).style.opacity = 0.5;
      },
      after: ({ target }) => {
        document.querySelector(target).style.opacity = 0;
      },
    },
    {
      target: ".ui-filter-button-createdAt",
      content: (
        <>
          <p>{t("projects.filters.timestamps")}</p>
        </>
      ),
      placement: "bottom",
      before: ({ target }) => {
        document.querySelector(target).style.opacity = 0.5;
      },
      after: ({ target }) => {
        document.querySelector(target).style.opacity = 0;
      },
    },
    {
      target: ".ui-button-clear-all-filter",
      content: (
        <>
          <p>{t("projects.filters.clearAllFilters")}</p>
          <p>{t("projects.filters.clearAllFilters2")}</p>
        </>
      ),
      placement: "right",
    },
    {
      target: ".ui-tablePagination",
      content: (
        <>
          <p>{t("projects.tablePagination.paragraph1")}</p>
        </>
      ),
      placement: "top",
    },
    {
      target: ".ui-exportProject-button",
      content: (
        <>
          <p>{t("projects.exportProject.paragraph1")}</p>
        </>
      ),
      placement: "left",
    },
    {
      target: ".ui-deleteProjects-button",
      content: (
        <>
          <p>{t("projects.deleteProjects.paragraph1")}</p>
        </>
      ),
      placement: "left",
    },
    ...(!isAdmin
      ? [
          {
            target: ".ui-new-project-button",
            content: (
              <>
                <p>{t("projects.createProject.paragraph1")}</p>
              </>
            ),
            placement: "right",
          },
        ]
      : []),
  ];

  return <Tutorial steps={steps} section="projects" />;
}

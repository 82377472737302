import VisibilityOn from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, IconButton } from "@mui/material";
import StatusIcon from "../widgets/StatusIcon";

export default function StatusAndVisibility({ visible, setVisible, status }) {
  return (
    <Box>
      {status === "success" ? (
        <IconButton onClick={() => setVisible(!visible)}>
          {visible ? (
            <VisibilityOn color="action" />
          ) : (
            <VisibilityOff color="action" />
          )}
        </IconButton>
      ) : (
        <StatusIcon status={{ status }} />
      )}
    </Box>
  );
}

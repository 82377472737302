import { useState } from "react";

export default function useVisibilities(initialState = {}) {
  const [visibility, setVisibility] = useState(initialState);

  const isVisible = (key, defaultVisibility) => {
    return visibility[key] ?? defaultVisibility;
  };
  const setVisible = (key, newState) => {
    setVisibility({ ...visibility, [key]: newState });
  };

  return { isVisible, setVisible };
}

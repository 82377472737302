import React from "react";
import { useAuth } from "../providers/authProvider";
import { Outlet } from "react-router-dom";
import Loading from "../Components/Loading";

export const PrivateRoute = () => {
  const { isAuthenticated, signinRedirect } = useAuth();
  if (!isAuthenticated()) {
    signinRedirect();
    return <Loading />;
  }
  return <Outlet />;
};

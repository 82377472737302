import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import MainLayout from "../../layout/MainLayout";
import OrganizationApiProvider from "../../providers/OrganizationApiProvider";
import { withProjectApiProvider } from "../../providers/ProjectApiProvider";
import { useProfile } from "../../providers/UserProfileProvider";
import {
  HOME,
  ORGANIZATION,
  ORGANIZATIONS,
  PROJECT,
  PROJECTS,
} from "../system/Breadcrumbs";
import BackToProjectButton from "./BackToProjectButton";
import ProjectHeader from "./ProjectHeader";

function ProjectSubpage({ title, crumb, children }) {
  const { organizationId } = useParams();
  const { isAdmin } = useProfile();

  const breadcrumbs = () => [
    HOME,
    ...(organizationId
      ? [...(isAdmin ? [ORGANIZATIONS] : []), ORGANIZATION]
      : []),
    PROJECTS,
    PROJECT,
    crumb,
  ];

  const Content = () => {
    return (
      <>
        <ProjectHeader title={title} />
        <Box
          sx={{
            my: { xs: 3, md: 3 },
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <BackToProjectButton />
        </Box>
      </>
    );
  };

  return (
    <>
      {organizationId ? (
        <MainLayout title={null} breadcrumbs={breadcrumbs()}>
          <OrganizationApiProvider>
            <Content />
          </OrganizationApiProvider>
        </MainLayout>
      ) : (
        <MainLayout title={null} breadcrumbs={breadcrumbs()}>
          <Content />
        </MainLayout>
      )}
    </>
  );
}

export default withProjectApiProvider(ProjectSubpage);

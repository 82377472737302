import { Grid, Paper, Typography } from "@mui/material";
import AdBox from "../Components/system/AdBox";
import { Breadcrumbs } from "../Components/system/Breadcrumbs";
import BetaAccessConsent from "../Components/system/BetaAccessConsent";

export default function MainLayout({ children, title, breadcrumbs }) {
  return (
    <>
      <Breadcrumbs levels={breadcrumbs} />
      <BetaAccessConsent />
      <Grid container minWidth="640px">
        <Grid item xs>
          <Paper sx={{ p: 3 }}>
            {!!title && (
              <Typography variant="h4" align="center" marginBottom={3}>
                {title}
              </Typography>
            )}
            {children}
          </Paper>
        </Grid>
        {/*  Temporary hide ads from users DAI-45 */}
        {/*<Grid item xs="auto">*/}
        {/*  <AdBox />*/}
        {/*</Grid>*/}
      </Grid>
    </>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import appReducer from "./reducers/app";
import { administratorsApi } from "./services/administrators";
import { organizationsApi } from "./services/organizations";
import { projectsApi } from "./services/projects";
import { systemSettingsApi } from "./services/systemSettings";
import { userProfileApi } from "./services/userprofile";
import { usersApi } from "./services/users";

const store = configureStore({
  reducer: {
    app: appReducer,
    [userProfileApi.reducerPath]: userProfileApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [organizationsApi.reducerPath]: organizationsApi.reducer,
    [administratorsApi.reducerPath]: administratorsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [systemSettingsApi.reducerPath]: systemSettingsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      userProfileApi.middleware,
      projectsApi.middleware,
      organizationsApi.middleware,
      administratorsApi.middleware,
      usersApi.middleware,
      systemSettingsApi.middleware
    ),
});

export default store;

export const persistor = persistStore(store);

import axios from "axios";
import FileSaver from "file-saver";
import JSZip from "jszip";
import config from "../config";
import File from "../model/File";
import store from "../store";
import { projectsApi } from "./projects";

export function uploadFile(prjId, fileId, newFile, token) {
  const formData = new FormData();
  formData.append("File", newFile);

  const fileModel = new File({ id: fileId, originalFileName: newFile.name });

  const updateFile = (fileId, patch) => {
    fileId =
      fileId === "upper" || fileId == "lower"
        ? `${fileId}Arch.file`
        : `toothRestorations.${fileId}.dieFile`;
    store.dispatch(
      projectsApi.endpoints.updateProject.initiate({
        id: prjId,
        patch: {
          [fileId]: patch,
        },
      })
    );
  };

  updateFile(fileId, {
    ...fileModel.raw,
    decimation: { status: "in progress" },
  });

  axios
    .post(mediastoreUrl(prjId, fileModel.rawFileName()), formData, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch(() => {
      updateFile(fileId, { decimation: { status: "failed" } });
    });
}

export const mediastoreUrl = (projectId, fileName) =>
  `${config.backendUrl}/medias/projects/${projectId}/files/${fileName}`;

function createDomElement(tag, attrs) {
  return Object.assign(document.createElement(tag), attrs);
}

export function browserDownload(url, asFilename, token) {
  const jwtInput = createDomElement("input", {
    type: "hidden",
    name: "token",
    value: token,
  });
  const idInput = createDomElement("input", {
    type: "hidden",
    name: "filename",
    value: asFilename,
  });
  const form = createDomElement("form", {
    method: "post",
    target: "_blank",
    action: url,
  });
  form.appendChild(jwtInput);
  form.appendChild(idInput);
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export function downloadFile(projectId, fileId, token) {
  return axios
    .get(mediastoreUrl(projectId, fileId), {
      responseType: "arraybuffer",
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((x) => x.data);
}

export async function zipAndDownload(files, downloadFilename) {
  const zip = new JSZip();
  await Promise.all(
    files.map(async ({ name, data }) => zip.file(name, await data))
  );
  FileSaver.saveAs(await zip.generateAsync({ type: "blob" }), downloadFilename);
}

import { Trans, useTranslation } from "react-i18next";
import Tutorial from "../widgets/Tutorial";

export default function ProjectInfoTutorial() {
  const { t } = useTranslation(["tutorial"]);

  const steps = [
    {
      target: "body",
      title: t("projectInfo.general.title"),
      content: (
        <>
          <p>{t("projectInfo.general.paragraph1")}</p>
          <p>{t("projectInfo.general.paragraph2")}</p>
        </>
      ),
      disableBeacon: true,
      placement: "center",
    },
    {
      target: ".ui-refnumber",
      content: (
        <>
          <p>{t("projectInfo.refNumber.paragraph1")}</p>
          <p>{t("projectInfo.refNumber.paragraph2")}</p>
        </>
      ),
      placement: "right",
    },
    {
      target: ".ui-additionnal-info",
      content: (
        <>
          <p>{t("projectInfo.additionnalInfo.paragraph1")}</p>
        </>
      ),
      placement: "right",
    },
    {
      target: ".ui-tooth-picker",
      content: (
        <>
          <p>{t("projectInfo.toothPicker.paragraph1")}</p>
          <p>{t("projectInfo.toothPicker.paragraph2")}</p>
          <p>{t("projectInfo.toothPicker.paragraph3")}</p>
        </>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <>
          <p>
            <Trans ns={["tutorial"]} i18nKey={"projectInfo.save.paragraph1"}>
              text0<em>text1</em>text2
            </Trans>
          </p>
          <p>{t("projectInfo.save.paragraph2")}</p>
          <p></p>
          <p></p>
        </>
      ),
      placement: "center",
    },
    {
      target: ".ui-workflow-next-button",
      content: <p>{t("projectInfo.next.paragraph1")}</p>,
      placement: "left",
    },
  ];

  return <Tutorial steps={steps} section="project info" />;
}

import { createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Project from "../model/Project";
import { useSocketIo } from "../providers/SocketIoProvider";
import {
  projectsApi,
  useGetProjectQuery,
  useUpdateProjectMutation,
} from "../services/projects";
import { useSnackbar } from "./SnackbarProvider";
import LoadingError from "../Components/system/LoadingError";
import Loading from "../Components/Loading";

const projectApiContext = createContext(null);

export const useProjectApi = () => useContext(projectApiContext);

export const ProjectApiConsumer = projectApiContext.Consumer;

const ProjectApiProvider = (props) => {
  const { projectId } = useParams();
  const { showAlert } = useSnackbar();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const socketIo = useSocketIo();

  function updateProjectFromServer(data) {
    dispatch(
      projectsApi.util.updateQueryData("getProject", data.id, () => data)
    );
  }

  const { data, error, isLoading, refetch } = useGetProjectQuery(projectId);
  const [updateProject] = useUpdateProjectMutation();

  useEffect(() => {
    if (socketIo) {
      socketIo.on("project updated", updateProjectFromServer);
      socketIo.on("connect", () => {
        // We just (re-)connected with server socket and thus we may have missed updates!
        // Therefore, force a refresh of project data, just in case.
        refetch();
      });
      return () => socketIo.off("project updated");
    }
  }, [socketIo]);

  if (isLoading) return <Loading />;
  if (error) return <LoadingError error={error} />;

  const saveProject = (patch) =>
    updateProject({ patch, id: data.id })
      .unwrap()
      .catch((e) => {
        showAlert(t("Failed to save project"));
        throw e;
      });

  const project = {
    project: new Project(data),
    saveProject,
  };

  return (
    <projectApiContext.Provider value={project}>
      {props.children}
    </projectApiContext.Provider>
  );
};

export function withProjectApiProvider(Component) {
  return (props) => (
    <>
      <ProjectApiProvider>
        <Component {...props} />
      </ProjectApiProvider>
    </>
  );
}
export default ProjectApiProvider;

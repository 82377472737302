import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import ProjectState from "../../model/ProjectState";

export default function DataUsageConsent() {
  const { project, saveProject } = useProjectApi();
  const { t } = useTranslation();

  const saveDataUsageConsent = (value) => {
    saveProject({
      dataUsageConsent: value,
      state: ProjectState.NEW,
    });
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={project.dataUsageConsent}
            onChange={(e) => saveDataUsageConsent(e.target.checked)}
          />
        }
        label={
          <Typography variant="caption">{t("projects.dataConsent")}</Typography>
        }
      />
    </FormGroup>
  );
}

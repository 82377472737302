import {
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function FeedbackWidget({ value, onChange }) {
  const { t } = useTranslation();

  const submitFeedback = (value) => () => {
    onChange(value);
  };
  return (
    <Box display="flex" justifyContent="center" className="ui-feedback">
      <Tooltip title={t("Very dissatisfied")}>
        <IconButton
          onClick={submitFeedback(-2)}
          color={value === -2 ? "secondary" : "default"}
        >
          <SentimentVeryDissatisfied fontSize="large" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Somehow dissatisfied")}>
        <IconButton
          onClick={submitFeedback(-1)}
          color={value === -1 ? "secondary" : "default"}
        >
          <SentimentDissatisfied fontSize="large" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Somehow satisfied")}>
        <IconButton
          onClick={submitFeedback(1)}
          color={value === 1 ? "secondary" : "default"}
        >
          <SentimentSatisfied fontSize="large" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Very satisfied")}>
        <IconButton
          onClick={submitFeedback(2)}
          color={value === 2 ? "secondary" : "default"}
        >
          <SentimentVerySatisfied fontSize="large" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

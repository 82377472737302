import { useTranslation } from "react-i18next";
import { HOME, USERS } from "../Components/system/Breadcrumbs";
import UsersTable from "../Components/system/UsersTable";
import MainLayout from "../layout/MainLayout";
//import AdministratorsTutorial from "../Components/tutorial/AdministratorsTutorial";

function Users() {
  const { t } = useTranslation();
  return (
    <MainLayout title={t("Users")} breadcrumbs={[HOME, USERS]}>
      {/* <AdministratorsTutorial /> */}
      <UsersTable />
    </MainLayout>
  );
}

export default Users;

import { DeleteForever, Download } from "@mui/icons-material";
import { Grid, IconButton, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import {} from "react";
import ProjectState from "../../model/ProjectState";
import { useAuth } from "../../providers/authProvider";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import { uploadFile } from "../../services/FileService";
import StatusIcon from "../widgets/StatusIcon";
import "./FileUpload.css";
import { browserDownload, mediastoreUrl } from "../../services/FileService";

export default function FileUpload(props) {
  const { model, checkAndConfirmProjectRollback, maxWidth } = props;

  const { getUser } = useAuth();

  const {
    project: { id: projectId },
    saveProject,
  } = useProjectApi();

  const handleClearFile = async (file) => {
    if (!(await checkAndConfirmProjectRollback())) return;
    clearFile(file);
  };

  const clearFile = async (file) => {
    const archFile =
      file === "upper" || file == "lower"
        ? `${file}Arch.file`
        : `toothRestorations.${file}.dieFile`;

    const patch = {
      [`${archFile}.decimation`]: null,
      [`${archFile}.originalFileName`]: null,
      state: ProjectState.SCANS,
    };

    saveProject(patch);
  };

  const changeHandler = async (event) => {
    if (!(await checkAndConfirmProjectRollback())) {
      event.target.value = "";
      return;
    }

    const user = await getUser();
    const file = event.target.files[0];
    uploadFile(projectId, model.id, file, user.access_token);
  };

  const download = async () => {
    const user = await getUser();
    const file = mediastoreUrl(projectId, model.rawFileName() + "/download");
    return browserDownload(file, model.originalFileName, user.access_token);
  };

  function statusIcon() {
    return (
      <Typography
        sx={{
          verticalAlign: "middle",
          display: "inline-flex",
          maxWidth: { maxWidth },
        }}
        overflow={"hidden"}
      >
        <StatusIcon status={model.decimation} sx={{ marginRight: "10px" }} />

        {model.originalFileName}
      </Typography>
    );
  }

  return (
    <Box sx={{ flexGrow: "1" }}>
      <Grid container wrap="nowrap" alignItems="center">
        <Grid item xs="auto" className="ui-file-upload">
          {model.decimation !== null ? (
            statusIcon()
          ) : (
            <TextField
              type="file"
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              name="file"
              onChange={changeHandler}
              accept=".stl,.off,.obj"
            />
          )}
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          <IconButton
            onClick={() => {
              download();
            }}
            sx={{
              visibility: model.decimation !== null ? "visible" : "hidden",
            }}
            disabled={model?.decimation?.status !== "success"}
          >
            <Download />
          </IconButton>
          <IconButton
            onClick={() => {
              handleClearFile(model.id);
            }}
            sx={{
              visibility: model.decimation !== null ? "visible" : "hidden",
            }}
          >
            <DeleteForever color="primary" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

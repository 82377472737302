import Container from "@mui/material/Container";
import NavBar from "../Components/system/NavBar";
import { Outlet } from "react-router-dom";
import UserProfileProvider from "../providers/UserProfileProvider";
import Footer from "../Components/system/Footer";

export default function MainRoute() {
  return (
    <>
      <UserProfileProvider>
        <NavBar />
        <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
          <Outlet />
        </Container>
        <Footer />
      </UserProfileProvider>
    </>
  );
}

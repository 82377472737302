export function toFdi(unsTooth) {
  if (unsTooth === null) return null;

  if (unsTooth < 1 || unsTooth > 32)
    throw new Error(`invalid tooth number: ${unsTooth}`);

  if (unsTooth <= 8) return 19 - unsTooth;
  else if (unsTooth <= 16) return unsTooth + 12;
  else if (unsTooth <= 24) return 55 - unsTooth;
  else if (unsTooth <= 32) return unsTooth + 16;
}

export function getToothLabel(tooth, notation) {
  return notation === "UNS" ? tooth : toFdi(tooth);
}

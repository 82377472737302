import { Trans, useTranslation } from "react-i18next";
import Tutorial from "../widgets/Tutorial";

export default function ArchScansTutorial({ expandSection }) {
  const { t } = useTranslation(["tutorial"]);

  const steps = [
    {
      target: "body",
      title: t("archScans.general.title"),
      content: (
        <>
          <p>{t("archScans.general.paragraph1")}</p>
        </>
      ),
      disableBeacon: true,
      placement: "center",
    },
    {
      target: ".ui-file-upload",
      content: (
        <>
          <p>{t("archScans.fileUpload.paragraph1")}</p>
          <p>{t("archScans.fileUpload.paragraph2")}</p>
          <p>{t("archScans.fileUpload.paragraph3")}</p>
        </>
      ),
      placement: "right",
      before: () => expandSection("upper"),
    },
    {
      target: ".ui-scans-preview",
      content: (
        <>
          <p>{t("archScans.scansPreview.paragraph1")}</p>
          <p>{t("common.meshPreview.controls")}</p>
        </>
      ),
      placement: "left",
      before: () => expandSection("upper"),
    },
    {
      target: ".ui-preview-toggle-fullscreen",
      content: (
        <>
          <p>{t("common.meshPreview.fullscreen")}</p>
        </>
      ),
      placement: "bottom",
    },
    {
      target: ".ui-arch-extents-picker",
      content: (
        <>
          <p>{t("archScans.extentPicker.paragraph1")}</p>
          <p>{t("archScans.extentPicker.paragraph2")}</p>
        </>
      ),
      placement: "right",
      before: () => expandSection("upper"),
    },
    {
      target: ".ui-lower-arch-scan-header",
      content: (
        <>
          <p>{t("archScans.lowerArch.paragraph1")}</p>
        </>
      ),
      placement: "right",
    },
    {
      target: ".ui-lower-arch-scan-header",
      content: (
        <>
          <p>{t("archScans.antagonistArches.paragraph1")}</p>
          <p>{t("archScans.antagonistArches.paragraph2")}</p>
          <p>{t("archScans.antagonistArches.paragraph3")}</p>
        </>
      ),
      placement: "right",
    },
    {
      target: ".ui-die-files",
      content: (
        <>
          <p>{t("archScans.dieFiles.paragraph1")}</p>
          <p>{t("archScans.dieFiles.paragraph2")}</p>
          <p>{t("archScans.dieFiles.paragraph3")}</p>
        </>
      ),
      placement: "right",
      before: () => expandSection("dies"),
    },
    {
      target: ".ui-die-input-menu",
      content: (
        <>
          <p>{t("archScans.dieMenu.paragraph1")}</p>
          <p>
            <Trans ns={["tutorial"]} i18nKey={"archScans.dieMenu.paragraph2"}>
              text0<em>text1</em>text2.
            </Trans>
          </p>
        </>
      ),
      placement: "top",
      before: () => expandSection("dies"),
    },
    {
      target: ".ui-project-menu",
      content: (
        <>
          <p>
            <Trans ns={["tutorial"]} i18nKey={"common.projectMenu"}>
              text0<em>text1</em>text2<em>text3</em>text4.
            </Trans>
          </p>
        </>
      ),
      placement: "bottom",
    },
    {
      target: ".ui-workflow-next-button",
      content: (
        <>
          <p>{t("archScans.next.paragraph1")}</p>
        </>
      ),
      placement: "left",
    },
  ];

  return <Tutorial steps={steps} section="arch scans" />;
}

import { AppBar } from "@mui/material";
import Copyright from "./Copyright";
import CookiesConsent from "./CookiesConsent";

export default function Footer() {
  return (
    <AppBar
      position="fixed"
      color="default"
      elevation={0}
      sx={{
        top: "auto",
        bottom: 0,
        borderTop: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      <Copyright />
      <CookiesConsent />
    </AppBar>
  );
}

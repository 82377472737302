import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import ProjectMenu from "./ProjectMenu";

export default function ProjectHeader({ title }) {
  return (
    <Box display="flex">
      <Typography component="h1" variant="h4" align="center" width="100%">
        {title}
      </Typography>
      <ProjectMenu />
    </Box>
  );
}

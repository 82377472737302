import { useOrganizationApi } from "../../providers/OrganizationApiProvider";
import EditableText from "../widgets/EditableText";

function OrganizationTitle({ edit, onClick, onDoneEditing }) {
  const { organization, updateOrganization } = useOrganizationApi();
  const handleDoneEditing = (newVal) => {
    if (newVal) updateOrganization({ name: newVal });
    onDoneEditing();
  };
  return (
    <EditableText
      variant={"h4"}
      edit={edit}
      text={organization.name}
      onClick={onClick}
      onDoneEditing={handleDoneEditing}
    />
  );
}

export default OrganizationTitle;

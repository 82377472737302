import { Clear, Search } from "@mui/icons-material";
import { FormGroup, IconButton, TextField } from "@mui/material";
import _ from "lodash";
import { useCallback, useRef, useState } from "react";

export default function ProjectRefNumberFilter({ filter, setFilter, dismiss }) {
  const [initialFilterValue] = useState(filter);
  const inputRef = useRef();

  const debouncedSetFilter = useCallback(_.debounce(setFilter, 100), [
    setFilter,
  ]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      dismiss();
    }
    if (event.key === "Escape") {
      setFilter(initialFilterValue);
      dismiss();
    }
  };

  const handleClear = () => {
    setFilter("");
    inputRef.current.value = "";
  };

  return (
    <FormGroup sx={{ p: 2 }}>
      <TextField
        defaultValue={filter}
        size="small"
        inputRef={(e) => {
          if (e) e.focus();
          inputRef.current = e;
        }}
        onFocus={(event) => {
          event.target.select();
        }}
        onChange={() => debouncedSetFilter(inputRef.current.value)}
        onKeyDown={handleKeyPress}
        InputProps={{
          startAdornment: (
            <Search
              sx={{ marginLeft: "-.25em", marginRight: ".25em" }}
            ></Search>
          ),
          endAdornment: (
            <IconButton
              sx={{
                marginRight: "-.5em",
                visibility: filter ? "visible" : "hidden",
              }}
              onClick={handleClear}
            >
              <Clear />
            </IconButton>
          ),
        }}
      ></TextField>
    </FormGroup>
  );
}

import { Checkbox, FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import * as React from "react";
import { useTranslation } from "react-i18next";

export default function ConfirmationDialog(props) {
  const { t } = useTranslation();

  const {
    message,
    show,
    onOk,
    onCancel,
    canDismiss,
    promptDoNotShowAgain,
    onDoNotShowAgain,
  } = props;
  let { title, buttons } = props;

  title = title || t("Confirmation");
  buttons = buttons || [t("Ok"), t("Cancel")];

  const onClose = () => canDismiss && onCancel();

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      keepMounted
      maxWidth="xs"
      open={show}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {message}
        {promptDoNotShowAgain && (
          <FormControlLabel
            sx={{ marginTop: "1em", marginBottom: "-1em" }}
            control={
              <Checkbox onChange={(e) => onDoNotShowAgain(e.target.checked)} />
            }
            label={t("Do not ask again")}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel}>
          {buttons[1]}
        </Button>
        <Button onClick={onOk}>{buttons[0]}</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  canDismiss: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  canDismiss: true,
};

import { Tooltip, Typography } from "@mui/material";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import fr from "javascript-time-ago/locale/fr";
import { useTranslation } from "react-i18next";

TimeAgo.addLocale(en);
TimeAgo.addLocale(fr);

export default function Timestamp({ time }) {
  const { i18n } = useTranslation();
  const timeAgo = new TimeAgo(i18n?.language?.substring(0, 2) || "en");
  return (
    <Tooltip title={time.toLocaleString(i18n.language)}>
      <Typography variant="inherit" display="inline">
        {timeAgo.format(time)}
      </Typography>
    </Tooltip>
  );
}

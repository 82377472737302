import { useTranslation } from "react-i18next";
import Tutorial from "../widgets/Tutorial";

export default function CompletedProjectTutorial() {
  const { t } = useTranslation(["tutorial"]);

  const steps = [
    {
      target: "body",
      title: t("completedProject.general.title"),
      content: (
        <>
          <p>{t("completedProject.general.paragraph1")}</p>
        </>
      ),
      disableBeacon: true,
      placement: "center",
    },
    {
      target: ".ui-file-download",
      content: (
        <>
          <p>{t("completedProject.download.paragraph1")}</p>
        </>
      ),
      placement: "right",
    },
    {
      target: ".ui-feedback",
      content: (
        <>
          <p>{t("completedProject.feedback.paragraph1")}</p>
          <p>{t("completedProject.feedback.paragraph2")}</p>
        </>
      ),
      placement: "left",
    },
    {
      target: ".ui-workflow-next-button",
      content: (
        <>
          <p>{t("completedProject.done.paragraph1")}</p>
        </>
      ),
      placement: "left",
    },
  ];

  return <Tutorial steps={steps} section="completedProject" />;
}

function getExtension(path) {
  const pos = path.lastIndexOf(".");
  if (pos == -1) return "";
  return path.substr(pos + 1);
}

export default class File {
  constructor(obj) {
    let data = {
      id: null,
      originalFileName: null,
      decimation: null,
      type: "stl",
    };
    Object.defineProperties(this, {
      id: {
        get: () => data.id,
        set: (x) => (data.id = x),
        enumerable: true,
      },
      originalFileName: {
        get: () => data.originalFileName,
        set: (x) => (data.originalFileName = x),
        enumerable: true,
      },
      decimation: {
        get: () => data.decimation,
        set: (x) => (data.decimation = x),
        enumerable: true,
      },
      type: {
        get: () => data.type,
        enumerable: true,
      },
      raw: {
        get: () => data,
      },
    });

    const type =
      obj && obj.originalFileName
        ? getExtension(obj.originalFileName)
        : data.type;

    // Handle old/deprecated schema version
    if (obj && "decimationStatus" in obj && !("decimation" in obj))
      obj.decimation = { status: obj.decimationStatus };

    Object.assign(data, { type: type, ...obj });
  }

  toJSON() {
    return this.raw;
  }

  isDecimated() {
    return this.decimation?.status === "success";
  }

  rawFileName() {
    return `raw_${this.id}.${this.type}`;
  }

  decimatedFileName() {
    return `decimated_${this.id}.${this.type}`;
  }

  isSet() {
    return !!this.originalFileName;
  }
}

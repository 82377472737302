import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useLocalStorage from "use-local-storage";

export default function BetaAccessConsent() {
  const { t } = useTranslation();
  const [consent, setConsent] = useLocalStorage("BetaAccessConsent", "");
  const [isChecked, setIsChecked] = useState(false);
  return consent !== "yes" ? (
    <Dialog open={true}>
      <DialogTitle>{t("DISCLAIMER")}</DialogTitle>
      <DialogContent>
        <Typography mt={1}>{t("endUserBetaAccessConsent.paragraph1")}</Typography>
        <Typography mt={1}>{t("endUserBetaAccessConsent.paragraph2")}</Typography>
        <Typography mt={1}>{t("endUserBetaAccessConsent.paragraph3")}</Typography>
        <Typography mt={1}>{t("endUserBetaAccessConsent.paragraph4")}</Typography>
        <ol type="A">
          <li>{t("endUserBetaAccessConsent.paragraph5")}</li>
          <li>{t("endUserBetaAccessConsent.paragraph6")}</li>
          <li>{t("endUserBetaAccessConsent.paragraph7")}</li>
        </ol>
        <Typography mt={1}>{t("endUserBetaAccessConsent.paragraph8")}</Typography>
        <Typography mt={1}>{t("endUserBetaAccessConsent.paragraph9")}</Typography>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          width={"100%"}
          justifyContent={"space-between"}
          paddingX={2}
        >
          <FormControlLabel
            control={<Checkbox />}
            checked={isChecked}
            onChange={(event) => setIsChecked(event.target.checked)}
            label={t("I agree")}
          />
          <Button onClick={() => setConsent("yes")} disabled={!isChecked}>
            {t("Proceed")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  );
}
